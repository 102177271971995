import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";

function blobUrlToFile(blobUrl, filename) {
    return fetch(blobUrl)
        .then(response => response.blob())
        .then(blob => new File([blob], filename, { type: blob.type }));
}

export const createEvent = createAsyncThunk('event/create', async (data) => {
    try {
        const { eventDescription, location, eventName, startdateandtime, enddateandtime, image, category } = data ?? {}
        const token = await getAccessToken()

        const formData = new FormData();

        const uriParts = image.split('.');
        const fileType = uriParts[uriParts.length - 1];
        const processedImage = await blobUrlToFile(image, `eventbanner.png`);


        formData.append('image', processedImage, processedImage.name);
        formData.append('eventDescription', eventDescription)
        formData.append('location', location)
        formData.append('eventName', eventName)
        formData.append('category', category)
        formData.append('startdateandtime', startdateandtime)
        formData.append('enddateandtime', enddateandtime)
        console.log(formData)


        const request = await fetch(`${apiUrl}/event/api/create/profile`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        })

        const response = await request.json()
        console.log('created event; ', response)
        if (response.status === 'success') {
            return response.data
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getMyEvent = createAsyncThunk('event/my-event', async () => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('my events list; ', response)
        if (response.status === 'success') {
            return response.data
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getSuggestedEvent = createAsyncThunk('event/suggested-event', async () => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/get/suggested`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('suggested events list; ', response)
        if (response.status === 'success') {
            return response.data
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getInterestedEvent = createAsyncThunk('event/interested-event', async () => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/interested/events`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('interested events list; ', response)
        if (response.status === 'success') {
            return response.events
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getGoingEvent = createAsyncThunk('event/going-event', async () => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/going/events`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('going events list; ', response)
        if (response.status === 'success') {
            return response.data
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getInvitedEvent = createAsyncThunk('event/invited-event', async () => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/invitations/fetch`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('invited events list; ', response)
        if (response.message === 'Invitation gotten successfully') {
            return response.invitations
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const toggleInterestedEvent = createAsyncThunk('event/toggle-interested-event', async (eventId) => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/toggle-intrested-status/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('toggle interested res; ', response)
        if (response.status === 'success') {
            return response.intrestedStatus
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const toggleGoingEvent = createAsyncThunk('event/toggle-going-event', async (eventId) => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/toggle-going-status/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('toggle going res; ', response)
        if (response.status === 'success') {
            return response.goingStatus
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const searchEvent = createAsyncThunk('event/search', async (querry) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/events/search/search?query=${querry}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('search evnet res; ', response)
        if (response.status === 'success') {
            return response.data
        }
        else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const removeEventInvitation = createAsyncThunk('event/invitation-remove-event', async (invitationId) => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/invitation/${invitationId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('remove event invite; ', response)
        if (response.status === 'success') {
            const res = {message: response.message, invitationId}
            return res
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})
export const inviteUserToEvent = createAsyncThunk('event/invite-user-event', async ({ eventId, invitedUsers }) => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/api/events/invite/${eventId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(invitedUsers)
        })

        const response = await request.json()
        console.log('invite User; ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getEventCategory = createAsyncThunk('event/category', async (category) => {
    try {
        console.log(category)
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/events/events/user/${category}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('category events list; ', response)
        if (response.status === 'success') {
            return {category, data: response.data}
        } else {
            throw new Error(response.message)
        }

    }
    catch (error) {
        console.log('something happened; ', error.message)
        throw new Error(error.message)
    }
})

