import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";
import { toast } from "react-toastify";

function blobUrlToFile(blobUrl, filename) {
    return fetch(blobUrl)
        .then(response => response.blob())
        .then(blob => new File([blob], filename, { type: blob.type }));
  }

export const createAlbum = createAsyncThunk('album/create', async (data) => {
    try {
        const { file, albumDescription, name } = data
        const formData = new FormData()
        if (file) {
            const processedImage = await blobUrlToFile(file, `albumbanner.png`);
            formData.append('file', processedImage, processedImage.name);
        }
        formData.append('name', name)
        formData.append('albumDescription', albumDescription)


        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/album/api/create/profile/album`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        })

        const response = await request.json()
        console.log('created album; ', response)

        if (response.status === 'success') {
            return response.data
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const deleteAlbum = createAsyncThunk('album/delete', async (albumId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/album/api/cover/:albumId${albumId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()
        console.log('removed media ; ', response)

        if (response.message === 'Deleted the image(s), album content, and album successfully') {
            return { albumId, status: response.status }
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const addMediaToAlbum = createAsyncThunk('album/add-media', async ({ files, albumId, name }) => {
    try {
     
        const token = await getAccessToken()
        const formData = new FormData
        if (files && files.length > 0) {
            files.forEach((file, index) => {
                const uri = file.uri;
                const uriParts = uri.split('.');
                const fileType = uriParts[uriParts.length - 1];

                formData.append(`files`, {
                    uri: uri,
                    type: `post/${fileType}`,
                    name: `post_${index}.${fileType}`,
                });
            });
        }
        const request = await fetch(`${apiUrl}$/album/api/album/files/${albumId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            },
            body: formData
        })

        const response = await request.json()
        console.log('added media; ', response)

        if (response.message === 'Files uploaded successfully') {
            toast.success("Files uploaded successfully")

            return response.album
        } else {
            toast.error("Files uploaded failed")

            throw new Error(response.message)
        }
    }
    catch (error) {
        toast.error("File failed to Upload")

        throw new Error(error.message)
    }
})

export const removeMediaFromAlbum = createAsyncThunk('album/remove-media', async ({ albumId, imageName }) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/album/api/album/files/${albumId}/${imageName}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()
        console.log('removed media ; ', response)
        if (response.message === 'File deleted successfully') {
            return { albumId, status: response.status }
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getUserAlbum = createAsyncThunk('album/fetch', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/albums/user/albums`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })

        const response = await request.json()
        console.log('fetched album; ', response)
        if (response.message === 'Albums retrieved successfully') {
            return response.data
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getAlbumContent = createAsyncThunk('album/content', async (albumId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/albums/contents/${albumId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })

        const response = await request.json()
        console.log('album content; ', response)
        if (response.status === 'success') {
            const data = response.data
            return { albumId, data }

        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})