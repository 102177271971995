import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";
import { toast } from "react-toastify";

function blobUrlToFile(blobUrl, filename) {
    return fetch(blobUrl)
        .then(response => response.blob())
        .then(blob => new File([blob], filename, { type: blob.type }));
}


export const createGroup = createAsyncThunk('group/create', async (data) => {
    console.log(JSON.stringify(data))
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/create-group`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const response = await request.json()
        if (response.status === 'success') {
            console.log('group created; ', response)
            return response.group
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
});

export const uploadGroupBanner = createAsyncThunk('group/upload-banner', async ({ groupId, image }) => {
    try {
        const token = await getAccessToken()

        const formData = new FormData();

        const uriParts = image.split('.');
        const fileType = uriParts[uriParts.length - 1];

        const processedImage = await blobUrlToFile(image, 'new_story.png');
        console.log('incomin file: ', processedImage)
        formData.append('image', processedImage, processedImage.name)

        const request = await fetch(`${apiUrl}/image/api/group/avatar/${groupId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                // 'Content-Type': 'multipart/form-data',
            },
            body: formData
        })

        const response = await request.json()
        console.log('group banner uploaded ', response)
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getSuggestedGroups = createAsyncThunk('group/suggested-groups', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/groups/SuggestedGroups`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        const response = await request.json()
        if (response.status === 'success') {
            return response.groups
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('error creating group', error.message)
    }
})

export const getMyGroups = createAsyncThunk('group/my-groups', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/getAdmin/Groups`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        console.log('my group list ', response)
        if (response.status === 'success') {
            return response.groups
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getJoinedGroups = createAsyncThunk('group/joined-groups', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/groups/user`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        console.log('joined group list ', response)
        if (response.status === 'success') {
            return response.data
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})


export const getGroupMembers = createAsyncThunk('group/get-members', async (groupId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/get-group-members/${groupId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        if (response.status === 'success') {
            return response.members
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('error creating group', error.message)
    }
})

export const makeUserAdmin = createAsyncThunk('group/add-admin', async ({ groupId, userId }) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/groups/admin/add/${groupId}/${userId}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('error creating group', error.message)
    }
})

export const removeUserAdmin = createAsyncThunk('group/remove-admin', async ({ groupId, userId }) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/groups/admin/remove/${groupId}/${userId}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('error creating group', error.message)
    }
})

export const addGroupMember = createAsyncThunk('group/add-member', async (data) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/add-group-members`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const response = await request.json()
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('error creating group', error.message)
    }
})

export const removeGroupMember = createAsyncThunk('group/remove-member', async (data) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/groups/remove-group-members`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const response = await request.json()
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('error creating group', error.message)
    }
})

export const leaveGroup = createAsyncThunk('group/leave', async (groupId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/leaveGroup/${groupId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('error creating group', error.message)
    }
})

export const createGroupPost = createAsyncThunk('group/post/create', async ({ caption, files, groupId }) => {
    try {
        const token = await getAccessToken()

        const formData = new FormData()

        if (files && files.length > 0) {
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                const processedImage = await blobUrlToFile(file, `groupMedia_${index + 1}.png`);
                // console.log('images ', processedImage)

                formData.append('files', processedImage, processedImage.name)
            }

        }
        formData.append('caption', caption)
        // console.log('group post route ', `${apiUrl}${creatGroupPostAPI}${groupId}`)

        const request = await fetch(`${apiUrl}/image/api/group/post/${groupId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        })

        const response = await request.json()
        console.log('posting to group response; ', response)
        if (response.status === 'success') {
            return response.posts
        } else {
            toast.error("Post failed to Upload")

            throw new Error(response.message)
        }
    }
    catch (error) {
        toast.error("Post failed to Upload")

        throw new Error(error.message)
    }
})

export const joinPublicGroup = createAsyncThunk('group/group/join', async (groupId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/join/group/${groupId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        console.log('group joined response ', response)
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})
export const updateGroupInfo = createAsyncThunk('group/update', async ({ data, groupId }) => {
    // console.log(JSON.stringify(data))
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/groups/${groupId}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const response = await request.json()
        if (response.status === 'success') {
            console.log('group updated successfully; ', response)
            return response.message
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})
export const searchGroup = createAsyncThunk('group/search', async (querry) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/search/group?querry=${querry}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        if (response.status === 'success') {
            return response.groups
        }
        else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const pinGroup = createAsyncThunk('group/pin', async (groupId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/groups/${groupId}/pin`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        console.log('pin group response; ', response)
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const unpinGroup = createAsyncThunk('group/unpin', async (groupId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/chat/groups/${groupId}/pin`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        console.log('UNpin group response; ', response)
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})


