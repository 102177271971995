import React, { useState, useEffect, Link } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostComments, createComment, getCommentReplies, replyComment, likeComment, unlikeComment } from "../../redux/comment/commentAction";
import moment from "moment"; // Import moment library
import SendIcon from "../../assests/posts/send.png";
import profileImage from "../../assests/defaultImage.png";
import { AiOutlineSmile, AiOutlineClose } from "react-icons/ai";
import EmojiPicker from "emoji-picker-react";
import { MdOutlineCommentsDisabled } from "react-icons/md";



export const ReplyItems = ({ reply }) => {
    const dispatch = useDispatch();
    const { replyId, user, createdAt, text, commentId, postId, posting } = reply;
    const { UserName, firstName, lastName, profilePhoto } = user;
    const [isPosting, setIsPosting] = useState(false);
    const [time, setTime] = useState(createdAt);
    const [idReply, setIdReply] = useState(replyId.startsWith('new-') ? null : replyId);
    const profileImage = profilePhoto && profilePhoto.length > 0 ? profilePhoto[0]?.photoPublicUrl : '';


    useEffect(() => {
        if (posting) {
            setIsPosting(true);
        }
    }, [posting]);

    useEffect(() => {
        if (isPosting) {
            postReply();
        }
    }, [isPosting]);

    const postReply = async () => {
        try {
            if (isPosting) {
                const comment = {
                    text: text
                };
                const repliedData = await dispatch(replyComment({ postId, commentId, comment })).unwrap();
                setIsPosting(false);
                console.log('replied data:', repliedData);
            }
        }
        catch (error) {
            setIsPosting(false);
            console.log('error posting reply:', error.message);
        }
    };

    return (
        <div className="ml-10 "> {/* Adjust margin to position the reply below the comment */}
            <div className="flex items-center gap-2">
                <img
                    src={profileImage}
                    alt="Profile"
                    className="w-6 h-6 rounded-full"
                />
                <div className="flex gap-32"> {/* Use flex column to stack elements vertically */}
                    <div className="flex items-center gap-2">
                        <p className=" text[12px] ">{UserName}</p>
                        <p className="text-[12px]">{text}</p>
                    </div>
                    <div className="flex items-center gap-2">
                        {isPosting ?
                            <p className="text-yellow-600 text-[12px]">Posting...</p> :
                            <p className="text-yellow-600 text-[12px]">{moment(time).fromNow()}</p>}
                    </div>

                </div>
            </div>
        </div>
    );
};

export const CommentItem = ({ comment, commentText, handleReplyComment, replyMode, commentReplyId }) => {
    const dispatch = useDispatch()
    const { userData } = useSelector((state) => state.user)

    const { commentId, createdAt, liked, likesCount, postId, replyCount, text, user, posting } = comment
    const { firstName, lastName, UserName, profilePhoto, userId } = user
    const [repliedComments, setRepliedComments] = useState([]);
    const [time, setTime] = useState(createdAt)
    const [idComment, setIdComment] = useState(commentId.startsWith('new-') ? null : commentId)
    const [likeStatus, setLikeStatus] = useState(liked);
    const [isPosting, setIsPosting] = useState(false)
    const profileImage = profilePhoto && profilePhoto.length > 0 ? profilePhoto[0]?.photoPublicUrl : ''

    const handleLikeComment = () => {
        // Toggle liked state of the comment
        if (likeStatus) {
            setLikeStatus(false)
            dispatch(unlikeComment(idComment))
        } else {
            setLikeStatus(true)
            dispatch(likeComment(idComment))
        }
    };

    useEffect(() => {
        if (posting && postId) {
            setIsPosting(true)
        }
    }, [posting])

    useEffect(() => {
        if (isPosting) {
            // setIsPosting(false)
            postComment()
        }
    }, [isPosting])

    const uniqueId = function generateUUID() {
        let uuid = 'new-' + Math.random().toString(36).substr(2, 9);
        return uuid;
    }


    useEffect(() => {
        if (replyMode) {
            if (commentReplyId === idComment) {
                const UUID = uniqueId()
                const newComment = {
                    user: {
                        userId: userData.userId,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        profilePhoto: userData.profilePhoto, // Your profile image
                    },
                    postId: postId,
                    commentId: idComment,
                    replyId: UUID,
                    text: commentText,
                    posting: true,
                    liked: false,
                };
                setRepliedComments([newComment, ...repliedComments])
            }
        }
    }, [replyMode])

    const postComment = async () => {
        try {
            if (isPosting && !idComment) {
                const comment = {
                    text: text
                }
                const postedComment = await dispatch(createComment({ postId: postId, comment: comment })).unwrap()
                setIsPosting(false)
                setIdComment(postedComment.commentId)
                setTime(postedComment.createdAt)
                return
            }
        }
        catch (error) {
            console.log('error posting comment ', error.message)
        }
    }

    // console.log('time ; ',time)

    return (
        <div className="flex flex-col w-full">
            <div className="text-sm font-outfit items-center mb-2">
                <div className="flex gap-2">
                    <img
                        src={profileImage}
                        alt="Profile"
                        className="w-10 h-10 rounded-full"
                    />
                    <div className="flex items-center gap-10 justify-between">
                        <p className="font-bold ">{firstName} {lastName}</p>

                    </div>
                </div>
                <div className="mt-4">

                    <div className="flex flex-col justify-between">
                        <p className="bg-[#1C1B19] p-1 rounded-full inline-block break-words">{text}</p>
                        <div className="flex gap-2 mt-2">
                            {idComment && (
                                <button
                                    onClick={handleLikeComment}
                                    className={`text-gray-500 hover:text-white ${likeStatus ? "text-yellow-500" : ""}`}
                                >
                                    {likeStatus ? "Liked" : "Like"}
                                </button>
                            )}
                            {idComment && (
                                <button
                                    onClick={() => handleReplyComment(idComment, firstName)}
                                    className="text-gray-500 hover:text-white"
                                >
                                    Reply
                                </button>
                            )}
                            {isPosting && !time ? (
                                <p className="text-white">posting...</p>
                            ) : (
                                <p className="text-white">{moment(time).fromNow()}</p>
                            )}
                        </div>
                    </div>

                </div>

            </div>
            <div>
                {/* Display replies under the commented user */}
                {repliedComments.map((reply) => (
                    <ReplyItems key={reply.replyId} reply={reply} />
                ))}
            </div>
        </div>
    )
}



const CommentModal = ({ postUrl, onClose, postId }) => {
    const dispatch = useDispatch()
    const [caption, setCaption] = useState('');
    const { profilePhotoUrl, userData } = useSelector((state) => state.user);
    const [replyMode, setReplyMode] = useState(false)
    const [replyCommentId, setReplyCommentId] = useState(null)
    const [comments, setComments] = useState([])
    const [commentText, setCommentText] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);


    const handleEmojiPickerToggle = () => {
        setShowEmojiPicker(!showEmojiPicker);
    };


    // const handleInputChange = (event) => {
    //     setCaption(event.target.value);
    // };

    const handleEmojiSelect = (emojiObject) => {
        const emoji = emojiObject.emoji; // Extract the emoji symbol from the emojiObject
        setCommentText(commentText + emoji); // Append the selected emoji to the current caption value
        setShowEmojiPicker(true); // Hide the emoji picker after selection
    };

    useEffect(() => {
        if (postId) {
            fetchPostComment()
        }
    }, [postId])

    const fetchPostComment = async () => {
        try {
            const comments = await dispatch(getPostComments(postId)).unwrap()
            setComments(comments)
            // console.log('comment retrieved successfully', comments)
        }
        catch (error) {
            console.log('error copying ', error)
        }
    }

    const uniqueId = function generateUUID() {
        let uuid = 'new-' + Math.random().toString(36).substr(2, 9);
        return uuid;
    }

    const handleCommentSubmit = () => {
        const UUID = uniqueId()
        // Add logic to submit comment
        if (commentText.trim() !== "") {
            if (!commentText.includes('@')) {
                const newComment = {
                    user: {
                        userId: userData.userId,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        profilePhoto: userData.profilePhoto, // Your profile image
                    },
                    postId: postId,
                    commentId: UUID,
                    text: commentText,
                    posting: true,
                    liked: false,
                };
                setComments([newComment, ...comments])
                return setCommentText('')
            }
            else {
                setReplyMode(true)
                setTimeout(() => {
                    setReplyMode(false)
                    setCommentText("")
                    setReplyCommentId(null)
                }, 300);
            }
        }
    };

    const handleReplyComment = (commentId, username) => {
        // Set commentText state with "@" + username
        setReplyCommentId(commentId)
        setCommentText("BD_@" + username + " ");
    };



    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-90" onClick={onClose} />
            <div className="bg-[#353530] w-[80%] h-3/4 p-4 rounded-[22px] flex z-10">
                <div className="w-[50%] p-2 flex flex-col space-y-2">
                    {postUrl.length > 0 && (
                        <div className="relative w-full h-2/3">
                            <img
                                src={postUrl[0].postUrl}
                                alt={`Post 1`}
                                className="rounded-t-[22px] w-full h-full object-cover"
                            />
                        </div>
                    )}
                    {postUrl.length > 1 && (
                        <div className="flex w-full h-1/3 space-x-2">
                            <div className="relative w-1/2">
                                <img
                                    src={postUrl[1].postUrl}
                                    alt={`Post 2`}
                                    className="rounded-bl-[22px] w-full h-full object-cover"
                                />
                            </div>
                            <div className="relative w-1/2">
                                <img
                                    src={postUrl[2].postUrl}
                                    alt={`Post 3`}
                                    className="rounded-br-[22px] w-full h-full object-cover"
                                />
                            </div>
                        </div>
                    )}
                </div>


                <div className="w-[30%] p-2 flex flex-col justify-between ">
                    <div className="flex justify-end">
                        <AiOutlineClose onClick={onClose} className="text-white cursor-pointer" size={20} />
                    </div>
                    <div className="w-full">
                        {comments && comments.length > 0 ? (
                            comments.map((comment, index) => (
                                <CommentItem
                                    key={comment.commentId}
                                    commentText={commentText}
                                    comment={comment}
                                    handleReplyComment={handleReplyComment}
                                    replyMode={replyMode}
                                    commentReplyId={replyCommentId}
                                />
                            ))
                        ) : (
                            <div className="items-center text-center justify-center h-full">
                                <div className="mt-[10%]">
                                    <MdOutlineCommentsDisabled className="w-20 mt-8 mx-auto" size={60} />
                                    <h1 className="mt-2">No comments to show</h1>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col relative">
                        <div className="border-b"></div>
                        <div className="flex bg-[#1C1B19] mt-2 font-outfit text-sm rounded-full items-center">
                            <input
                                type="text"
                                placeholder="Add a comment..."
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                                className="outline-0 bg-[#1C1B19] p-1 pl-3 rounded-full w-full font-outfit"
                            />
                            {/* Emoji Picker */}
                            <AiOutlineSmile
                                className="w-6 h-6 cursor-pointer ml-2"
                                onClick={handleEmojiPickerToggle}
                            />
                            {/* Send Button */}
                            <button onClick={() => handleCommentSubmit()} className="ml-2">
                                <img src={SendIcon} alt="SendIcon" className="w-8  hover:text-black" />
                            </button>
                        </div>
                        {/* Emoji Picker */}
                        {showEmojiPicker && (
                            <div className="absolute bottom-[-2px] left-[-74%] z-50">
                                <EmojiPicker open={showEmojiPicker} onEmojiClick={handleEmojiSelect} theme='dark' className="" />
                            </div>
                        )}
                    </div>
                </div>
            </div>



        </div>
    );
};

export default CommentModal;
