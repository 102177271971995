import { postUserStory, getFriendsStory, getMyStory } from "./storyAction";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    storyMediaPreview: '',
    myStoryLoding: false,
    loading: false,
    error: null,
    stories: [],
    storyStatus: 'idle',
    storyIndex: 0
}

const storiesSlice = createSlice({
    name: 'story',
    initialState,
    reducers: {
        updateStoryPreview: (state, action) => {
            state.storyMediaPreview = action.payload
        },
        resetStoryStatus: (state) => {
            state.storyStatus = initialState.storyStatus
        },
        updateStoryIndex: (state, action) => {
            state.storyIndex = action.payload
        }

    },

    extraReducers: (builder) => {
        builder
            .addCase(postUserStory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(postUserStory.fulfilled, (state, action) => {
                state.loading = false;
                // state.stories = action.payload;
                state.storyStatus = 'success'
            })
            .addCase(postUserStory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.storyStatus = 'failed'
            });

        builder
            .addCase(getMyStory.pending, (state) => {
                state.myStoryLoding = true;
                state.error = null;
            })
            .addCase(getMyStory.fulfilled, (state, action) => {
                state.loading = false;
                // Put payload from getMyStory at the top of the stories array
                if (action.payload) {
                    state.stories = [action.payload, ...state.stories.filter(story => story.userId !== action.payload.userId)];

                    console.log('my gotten stories; ', state.stories)
                }

            })
            .addCase(getMyStory.rejected, (state, action) => {
                state.myStoryLoding = false;
                state.error = action.error.message;
            });

        builder
            .addCase(getFriendsStory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getFriendsStory.fulfilled, (state, action) => {
                state.loading = false;
                // Replace all other stories with incoming stories from payload, keeping the one from getMyStory at the top
                if (action.payload.length > 0) {
                    const myStoryUserId = state.stories.length > 0 ? state.stories[0].userId : null;
                    const filteredStories = action.payload.filter(story => story.userId !== myStoryUserId);
                    if (state.stories.length > 0) {
                        state.stories = [state.stories[0], ...filteredStories];
                    } else {
                        state.stories = [...filteredStories];
                    }

                }
            })
            .addCase(getFriendsStory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { updateStoryPreview, updateStoryIndex, resetStoryStatus } = storiesSlice.actions
export default storiesSlice.reducer;