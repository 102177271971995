export const saveAccessToken = async (token) => {
  try {
    if (token) {
      sessionStorage.setItem("accessToken", token);
      console.log("Token saved successfully");
      return true;
    } else {
      console.log("No Token Provided");
    }
  } catch (error) {
    console.log("Error Saving Token; ", error.message);
  }
};

export const getAccessToken = async () => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken === undefined || !accessToken) {
      console.log("Access Token not found");
      return null;
    } else {
      return accessToken;
    }
  } catch (error) {
    console.log("error retrievig access token; ", error);
  }
};
