// src/App.js
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import store from './redux/store';
import routes from './routesConfig';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-center"
        progressStyle={{ backgroundColor: '#BE9524', color: '#BE9524' }}
        bodyStyle={{ backgroundColor: '#000000' }}
        toastStyle={{ backgroundColor: '#000000' }}
      />
      <BrowserRouter>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
