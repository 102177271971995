import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";


function blobUrlToFile(blobUrl, filename) {
    return fetch(blobUrl)
        .then(response => response.blob())
        .then(blob => new File([blob], filename, { type: blob.type }));
}

function formatUserData(data) {
    const formattedData = {
        userId: data[0].user.userId,
        firstName: "My",
        lastName: "Stories",
        profilePhoto: data[0].user.profilePhoto,
        story: []
    };

    data.forEach(obj => {
        const { user, userId, ...rest } = obj;
        formattedData.story.unshift(rest); // Add the object to the beginning of the array
    });

    return formattedData;
}


export const postUserStory = createAsyncThunk('stories/postUserStory', async ({ file, backgroundColor, caption }) => {
    try {
        const token = await getAccessToken()

        const formData = new FormData();

        if (file) { // Check if file is provided
            // const uriParts = file.split('.');
            // const fileType = uriParts[uriParts.length - 1];

            const processedImage = await blobUrlToFile(file, 'new_story.png');
            console.log('incomin file: ', processedImage)



            formData.append('image', processedImage, processedImage.name)
        }

        formData.append('backgroundColor', backgroundColor);
        formData.append('caption', caption);

        // console.log('story data ', formData)

        const request = await fetch(`${apiUrl}/stories/api/profile`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                // 'Content-Type': 'multipart/form-data',
            },
            body: formData,
        });

        const response = await request.json();
        console.log('story response; ', response)
        if (response.status === 'success') {
            return console.log('done')
        }
        else {
            throw new Error(response.message)
        }
    } catch (error) {
        throw new Error(error.message)
    }
}
);


export const getMyStory = createAsyncThunk('stories/my-stories', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/stories/api`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })

        const response = await request.json()
        console.log('my stories response ; ', response)
        if (response.message === "story retrieved successfully") {
            const formattedData = formatUserData(response.post)
            return formattedData
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getFriendsStory = createAsyncThunk('story/get-friends-story', async (userId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/stories/api/friends-stories`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        const response = await request.json()
        console.log('friends stories; ', response)
        // console.log('friends pics; ', response.friendStories[0].profilePhoto)

        if (response.status === 'success') {
            return response.friendStories
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('story error; ', error.message)
        throw new Error(error.message)
    }
})