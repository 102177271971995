import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails } from "./usersAction";

const initialState = {
  userDataLoading: false,
  userData: [],
  userDataError: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.userDataLoading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userDataLoading = false;
        state.userData = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.userDataLoading = false;
        state.userDataError = action.error.message;
      });
  },
});

export default usersSlice.reducer;
