import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";
import { toast } from "react-toastify";

function blobUrlToFile(blobUrl, filename) {
    return fetch(blobUrl)
        .then(response => response.blob())
        .then(blob => new File([blob], filename, { type: blob.type }));
}


export const createPost = createAsyncThunk('post/create', async ({ caption, files, type, backgroundColour }) => {
    try {
        const token = await getAccessToken()

        const formData = new FormData()
        if (files && files.length > 0) {
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                const processedImage = await blobUrlToFile(file, `postMedia_${index + 1}.png`);
                // console.log('images ', processedImage)

                formData.append('files', processedImage, processedImage.name)
            }
        }
        formData.append('caption', caption)
        formData.append('backgroundColour', backgroundColour)
        formData.append('type', type)

        console.log('post data ', formData)


        const request = await fetch(`${apiUrl}/files/api/uploads`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        })

        const response = await request.json()
        console.log('posting response; ', response)
        if (response.status === 'success') {
            return response.posts
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const deletePost = createAsyncThunk('post/delete', async ({ userId, postId }) => {
    try {
        const token = await getAccessToken()

        const request = await fetch(`${apiUrl}/files/api/uploads/single/${userId}/${postId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('deleted post response; ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getUserFeed = createAsyncThunk('post/feed', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/admin/uploads`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        // console.log('feed data ', response)

        if (response.status === 'success') {
            return response.posts
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        console.log('feed error ', error.message)
        throw new Error(error.message)
    }
})

export const getMyPost = createAsyncThunk('post/get-my-post', async (userId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/files/api/uploads/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()

        console.log('my post ', response)
        if (response.message === 'Posts retrieved successfully') {
            return response.posts
        } else {
            console.log('like error ', response.message)
            return
        }
    }
    catch (error) {
        console.log('like error ', error.message)
        throw new Error(error.message)
    }
})

export const getUserPost = createAsyncThunk('post/get-user-post', async (userId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/files/api/uploads/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()

        console.log('user post ', response)
        if (response.message === 'Posts retrieved successfully') {
            return response.posts
        } else {
            console.log('like error ', response.message)
            return
        }
    }
    catch (error) {
        console.log('like error ', error.message)
        throw new Error(error.message)
    }
})

export const likePost = createAsyncThunk('post/like', async (postId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/like/${postId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()

        console.log('like response ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            console.log('like error ', response.message)
        }
    }
    catch (error) {
        console.log('like error ', error.message)
    }
})

export const unlikePost = createAsyncThunk('post/unlike', async (postId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/unlike/${postId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()

        console.log('unlike response ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            console.log('like error ', response.message)
        }
    }
    catch (error) {
        console.log('like error ', error.message)
    }
})

export const savePost = createAsyncThunk('post/save', async ({postId, post}) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/save-post/${postId}/save`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })

        const response = await request.json()
        console.log('save post response ', response)
        if (response.status === 'success') {
            toast.success('post saved successfully!')
            return {isSaved: true, post: post }
        } else {
            throw new Error(response.message)
        }
    } catch (error) {
        throw new Error(error.message)
    }
})

export const unsavePost = createAsyncThunk('post/unsave', async (postId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/save-post/${postId}/unsave`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })

        const response = await request.json()
        console.log('unsave post response ', response)
        if (response.status === 'success') {
            toast.success('post unsaved successfully!')
            return { unSaved: true, postId}
        } else {
            throw new Error(response.message)
        }
    } catch (error) {
        throw new Error(error.message)
    }
})

export const getSavedPost = createAsyncThunk('post/get-save-post', async (postId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/save-post/`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })

        const response = await request.json()
        console.log('getting saved post response ', response)
        if (response.status === 'success') {
            return response.savedPosts
        } else {
            throw new Error(response.message)
        }
    } catch (error) {
        throw new Error(error.message)
    }
})