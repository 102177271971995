import { createSlice } from "@reduxjs/toolkit";
import { login, signup, resetPassword, sendotp, verifyotp } from "./authAction";
import { saveAccessToken } from "../../helper/tokenStorage";

const initialState = {
  loading: false,
  authData: null,
  error: null,
  loginStatus: "idle",
  sendotpStatus: 'idle',
  isVerifiedOTP: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetLoginStatus: (state) => {
      state.loginStatus = initialState.loginStatus;
    },
    resetSendOtpStatus: (state) => {
      state.sendotpStatus = initialState.sendotpStatus
    },
    resetIsVerifiedOtp: (state) => {
      state.isVerifiedOTP = initialState.isVerifiedOTP
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.loading = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.authData = action.error;
      })

      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === "success") {
          const saveToken = saveAccessToken(action.payload.accessToken);
          if (saveToken) {
            state.authData = action.payload;
            state.loginStatus = "success";
          } else {
            state.loginStatus = "failed";
          }
        } else {
          state.loginStatus = "failed";
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.authData = action.error;
      });

    builder
      // sendt OTP state
      .addCase(sendotp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendotp.fulfilled, (state, action) => {
        state.loading = false;
        state.sendotpStatus = 'success'
      })
      .addCase(sendotp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    builder
      // verifyOTP state
      .addCase(verifyotp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyotp.fulfilled, (state, action) => {
        state.loading = false;
        state.isVerifiedOTP = true
      })
      .addCase(verifyotp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    builder
      //reset password state
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

  },
});

export const { resetLoginStatus, resetIsVerifiedOtp, resetSendOtpStatus } = authSlice.actions;
export default authSlice.reducer;
