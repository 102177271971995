import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";


export const createComment = createAsyncThunk('comment/create', async ({ postId, comment }) => {
    try {
        // console.log(JSON.stringify(comment))
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/comments/${postId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(comment)
        })
        const response = await request.json()

        console.log('comment response ', response)
        if (response.status === 'success') {
            return response.comment
        } else {
            console.log('comment error ', response.message)
        }
    }
    catch (error) {
        console.log('comment error ', error.message)
    }
})

export const getPostComments = createAsyncThunk('comment/post-comments', async (postId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/posts/${postId}/comments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()

        console.log('post comments response ', response)
        if (response.status === 'success') {
            return response.comments
        } else {
            console.log('comment error ', response.message)
        }
    }
    catch (error) {
        console.log('comment error ', error.message)
    }
})

export const likeComment = createAsyncThunk('comment/like', async (commentId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/comments/${commentId}/like`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()

        console.log('comment like response ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            console.log('comment like failed ', response.message)
        }
    }
    catch (error) {
        console.log('comment like error ', error.message)
    }
})

export const unlikeComment = createAsyncThunk('comment/unlike', async (commentId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/comments/${commentId}/unlike`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()

        console.log('comment unlike response ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            console.log('comment unlike failed ', response.message)
        }
    }
    catch (error) {
        console.log('comment like error ', error.message)
    }
})

export const getCommentReplies = createAsyncThunk('comment/post-comments', async (commentId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/comments/${commentId}/replies`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        const response = await request.json()

        console.log('post comments response ', response)
        if (response.status === 'success') {
            return response.replies
        } else {
            console.log('comment error ', response.message)
        }
    }
    catch (error) {
        console.log('comment error ', error.message)
    }
})

export const replyComment = createAsyncThunk('comment/reply', async ({ postId, commentId, comment }) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/posts/${postId}/comments/${commentId}/reply`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(comment)
        })
        const response = await request.json()

        console.log('comment reply response ', response)
        if (response.status === 'success') {
            return response.reply
        } else {
            console.log('comment reply error ', response.message)
        }
    }
    catch (error) {
        console.log('comment reply error ', error.message)
    }
})

export const deleteCommentReply = createAsyncThunk('comment/reply', async ({ replyId, comment }) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/post/${replyId}/comments/reply`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const response = await request.json()
        console.log('reply deleted response ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            console.log('reply  deleted error ', response.message)
        }
    }
    catch (error) {
        console.log('reply delete error ', error.message)
    }
})