import { createSlice } from "@reduxjs/toolkit";
import { createAlbum, deleteAlbum, getUserAlbum, addMediaToAlbum, getAlbumContent, removeMediaFromAlbum } from "./albumAction";

const initialState = {
    loading: false,
    creating: false,
    myAlbums: [],

    addingMedia: false,
    removingMedia: false,

    loadingAlbumContent: false,
    albumContents: {},
    error: null
}

const albumSlice = createSlice({
    name: 'album',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(createAlbum.pending, (state) => {
                state.creating = true
            })
            .addCase(createAlbum.fulfilled, (state, action) => {
                state.creating = false
                state.myAlbums.push(action.payload)
            })
            .addCase(createAlbum.rejected, (state, action) => {
                state.creating = false
                state.error = action.error.message
            })

        builder
            .addCase(getUserAlbum.pending, (state) => {
                state.loading = true
            })
            .addCase(getUserAlbum.fulfilled, (state, action) => {
                state.loading = false
                state.myAlbums = action.payload
            })
            .addCase(getUserAlbum.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

        builder
            .addCase(deleteAlbum.fulfilled, (state, action) => {
                const { albumId, status } = action.payload
                if (status === 'success') {
                    const filteredAlbum = state.myAlbums.filter(album => album.albumId !== albumId)
                    state.myAlbums = filteredAlbum
                    if (state.albumContents[albumId]) delete state.albumContents[albumId]
                }
            })
        
        builder 
            .addCase(getAlbumContent.pending, (state) => {
                state.loadingAlbumContent = true
            })
            .addCase(getAlbumContent.fulfilled, (state, action) => {
                state.loadingAlbumContent = false 
                const {albumId, data} = action.payload
                state.albumContents[albumId] = data
            })
            .addCase(getAlbumContent.rejected, (state, action) => {
                state.loadingAlbumContent = false
                state.error = action.error.message
            })
        
        builder
            .addCase(addMediaToAlbum.pending, (state) => {
                state.addingMedia = true
            })
            .addCase(addMediaToAlbum.fulfilled, (state, action) => {
                state.addingMedia = false
                const { albumId, postUrl } = action.payload
                state.albumContents[albumId]?.push(...postUrl)
            })
            .addCase(addMediaToAlbum.rejected, (state, action) => {
                state.addingMedia = false
                state.error = action.error.message
            })
        
        builder
            .addCase(removeMediaFromAlbum.pending, (state) => {
                state.removingMedia = true
            })
            .addCase(removeMediaFromAlbum.fulfilled, (state, action) => {
                state.removingMedia = false
                const { albumId, status } = action.payload
                if (status === 'success') {
                    const filterContent = state.albumContents[albumId]?.filter(album => album.albumId !== albumId)
                    state.albumContents[albumId] = filterContent
                }
            })
            .addCase(removeMediaFromAlbum.rejected, (state, action) => {
                state.removingMedia = false
                state.error = action.error.message
            })
    }
})

export default albumSlice.reducer