import { createSlice } from "@reduxjs/toolkit";
import { uploadReels, getReelsSearchPreview, searchReels, getReelsComment, getAllReels, getFriendsReels, saveReels, unsaveReels, getSavedReels } from "./reelsAction";

const initialState = {
    uploading: false,
    uploadStatus: 'idle',

    reelsPreviewLoading: false,
    reelsSearchPreview: [],

    searchingReels: false,
    searchedReels: [],

    loadingReels: false,
    currentReels: 'forYou',
    forYouReels: [],
    friendsReels: [],

    loadingSavedReels: false,
    savedReels: [],
    savedReelsError: null,

    reels: [],
    viewReels: [],

    commentLoading: false,

    error: null
}

const reelsSlice = createSlice({
    name: 'reels',
    initialState,
    reducers: {
        setCurrentReels: (state, action) => {
            if (action.payload === 'forYou') {
                state.currentReels = 'forYou'
                state.reels = state.forYouReels
            }
            else if (action.payload === 'friends') {
                state.currentReels = 'friends'
                state.reels = state.friendsReels
            }
        },

        setViewReels: (state, action) => {
            state.viewReels = action.payload
        },

        updateViewReels: (state, action) => {
            state.viewReels.push(...action.payload)
        },

        resetUploadStatus: (state) => {
            state.uploadStatus = initialState.uploadStatus
        }
    },
    extraReducers: (builder) => {

        builder
            .addCase(uploadReels.pending, (state) => {
                state.uploading = true
            })
            .addCase(uploadReels.fulfilled, (state) => {
                state.uploading = false
                state.uploadStatus = 'success'
            })
            .addCase(uploadReels.rejected, (state, action) => {
                state.uploading = false
                state.uploadStatus = 'rejected'
                state.error = action.error.message
            })

        builder
            .addCase(getAllReels.pending, (state) => {
                state.loadingReels = true
            })
            .addCase(getAllReels.fulfilled, (state, action) => {
                state.loadingReels = false
                state.viewReels.push(...action.payload)
                state.forYouReels = action.payload
                if (state.currentReels === 'forYou' && state.forYouReels.length > 0) {
                    state.reels = state.forYouReels
                }
            })
            .addCase(getAllReels.rejected, (state, action) => {
                state.loadingReels = false
                state.error = action.error.message
            })

        builder
            .addCase(getFriendsReels.pending, (state) => {
                state.loadingReels = true
            })
            .addCase(getFriendsReels.fulfilled, (state, action) => {
                state.loadingReels = false
                state.friendsReels = action.payload
                if (state.currentReels === 'friends' && state.friendsReels.length > 0) {
                    console.log('this was executed')
                    state.reels = state.friendsReels
                }
            })
            .addCase(getFriendsReels.rejected, (state, action) => {
                state.loadingReels = false
                state.error = action.error.message
            })

        builder
            .addCase(getReelsSearchPreview.pending, (state) => {
                state.reelsPreviewLoading = true
            })
            .addCase(getReelsSearchPreview.fulfilled, (state, action) => {
                state.reelsPreviewLoading = false
                state.reelsSearchPreview = action.payload
            })
            .addCase(getReelsSearchPreview.rejected, (state, action) => {
                state.reelsPreviewLoading = true
                state.error = action.error.message
            })

        builder
            .addCase(searchReels.pending, (state) => {
                state.searchingReels = true
            })
            .addCase(searchReels.fulfilled, (state, action) => {
                state.searchingReels = false
                state.searchedReels = action.payload
            })
            .addCase(searchReels.rejected, (state, action) => {
                state.searchingReels = true
                state.error = action.error.message
            })

        builder
            .addCase(getReelsComment.pending, (state) => {
                state.commentLoading = true
            })
            .addCase(getReelsComment.fulfilled, (state) => {
                state.commentLoading = false
            })
            .addCase(getReelsComment.rejected, (state, action) => {
                state.commentLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(saveReels.fulfilled, (state, action) => {
                const { message, reel } = action.payload
                const postId = reel.postId
                const index = state.viewReels.findIndex(post => post.postId === postId)
                const previewIndex = state.reelsSearchPreview.findIndex(post => post.postId === postId)

                if (index !== -1) {
                    state.viewReels[index] = {
                        ...state.viewReels[index],
                        savedByCurrentUser: true,
                        saveCount: state.viewReels[index].saveCount + 1
                    }
                }
                if (previewIndex !== -1) {
                    state.reelsSearchPreview[previewIndex] = {
                        ...state.reelsSearchPreview[previewIndex],
                        savedByCurrentUser: true,
                        saveCount: state.reelsSearchPreview[previewIndex].saveCount + 1
                    }
                }

                state.savedReels.unshift(reel)
            })

            .addCase(unsaveReels.fulfilled, (state, action) => {
                const reelId = action.payload
                const index = state.viewReels.findIndex(post => post.postId === reelId)
                const previewIndex = state.reelsSearchPreview.findIndex(post => post.postId === reelId)

                if (index !== -1) {
                    state.viewReels[index] = {
                        ...state.viewReels[index],
                        savedByCurrentUser: false,
                        saveCount: state.viewReels[index].saveCount - 1
                    }
                }

                if (previewIndex !== -1) {
                    state.reelsSearchPreview[previewIndex] = {
                        ...state.reelsSearchPreview[previewIndex],
                        savedByCurrentUser: false,
                        saveCount: state.reelsSearchPreview[previewIndex].saveCount - 1
                    }
                }

                const filteredSavedPost = state.savedReels.filter(post => post.postId !== reelId)
                state.savedReels = filteredSavedPost
            })

        builder
            .addCase(getSavedReels.pending, (state) => {
                state.loadingSavedReels = true
            })
            .addCase(getSavedReels.fulfilled, (state, action) => {
                state.loadingSavedReels = false
                state.savedReels = action.payload
            })
            .addCase(getSavedReels.rejected, (state, action) => {
                state.loadingSavedReels = false
                state.savedReelsError = action.error.message
            })
    }
})

export const { resetUploadStatus, setCurrentReels, setViewReels, updateViewReels } = reelsSlice.actions
export default reelsSlice.reducer;