import { createSlice } from "@reduxjs/toolkit";
import {
  getUser,
  updateUser,
  getMyFriends,
  searchUsers,
  deleteProfilePicture,
  uploadCoverPhoto,
  uploadProfilePics
} from "./userAction";

const initialState = {
  loading: false,
  coverPhotoLoading: false,
  userData: {},
  error: null,
  coverPhotoUrl: null,
  profilePhotoUrl: null,
  userDataLoading: false,
  userDataStatus: false,
  userDataError: null,
  userUpdateStatus: "idle",
  searchLoading: false,
  searchResults: [],
  searchError: null,
  deleting: false,
  friendLoading: false,
  myFriends: [],
  friendError: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUpdate: (state) => {
      state.userDataStatus = initialState.userDataStatus;
    },
    setSearchLoading: (state) => {
      state.searchLoading = true;
    },
    setSearchSuccess: (state, action) => {
      state.searchLoading = false;
      state.searchResults = action.payload;
    },
    setSearchError: (state, action) => {
      state.searchLoading = false;
      state.searchError = action.payload;
    },
    // resetSearchState: (state) => {
    //   state.searchLoading = false;
    //   state.searchResults = [];
    //   state.searchError = null;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.userDataLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userDataLoading = false;
        state.userData = action?.payload;

        if (state.userData && state.userData.coverPhoto && state.userData.coverPhoto.length > 0) {
          // const lastIndex = state.user.coverPhoto.length - 1;
          state.coverPhotoUrl = state.userData.coverPhoto[0].photoPublicUrl;
        } else {
          state.coverPhotoUrl = initialState.coverPhotoUrl
        }
        if (state.userData && state.userData.profilePhoto && state.userData.profilePhoto.length > 0) {
          // const lastIndex = state.user.profilePhoto.length - 1;
          state.profilePhotoUrl = state.userData.profilePhoto[0].photoPublicUrl;
        } else {
          state.profilePhotoUrl = initialState.profilePhotoUrl
        }
      })
      .addCase(getUser.rejected, (state, action) => {
        state.userDataLoading = false;
        state.userDataError = action.error.message;
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action?.payload;
        state.userDataStatus = "success";
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.userDataError = action.error.message;
      });

    builder
      .addCase(uploadProfilePics.fulfilled, (state, action) => {
        state.profilePhotoUrl = action.payload
      })
      .addCase(uploadCoverPhoto.fulfilled, (state, action) => {
        state.coverPhotoUrl = action.payload
      })

    builder
      .addCase(getMyFriends.pending, (state) => {
        state.friendLoading = true
      })
      .addCase(getMyFriends.fulfilled, (state, action) => {
        state.friendLoading = false
        state.myFriends = action.payload
      })
      .addCase(getMyFriends.rejected, (state, action) => {
        state.friendLoading = false
        state.friendError = action.error.message
      })

    builder

      .addCase(deleteProfilePicture.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(deleteProfilePicture.fulfilled, (state, action) => {
        state.deleting = false;
        // Update state after successful deletion if needed
        // For example, reset profile photo URL or any relevant data
      })
      .addCase(deleteProfilePicture.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
        // Handle error state management if needed
      });

    builder
      .addCase(searchUsers.pending, (state) => {
        state.searchLoading = true;
        state.searchResults = [];
        state.searchError = null;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchResults = action.payload;
        console.log("search data", state.searchResults);
        state.searchError = null;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.searchLoading = false;
        state.searchResults = [];
        state.searchError = action.error.message;
      });
  },
});

export const {
  resetUpdate,
  setSearchLoading,
  setSearchSuccess,
  setSearchError,
} = userSlice.actions;
export default userSlice.reducer;
