import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMyPost } from "../../redux/post/postAction";
import { setViewPostIndex, setViewPostUrl, setViewPostId } from "../../redux/post/postReducer";
import { useNavigate } from "react-router-dom";
import { likePost, unlikePost } from "../../redux/post/postAction";
import Profile from "../../assests/defaultImage.png";
import DownIcon from "../../assests/posts/down.png";
import moment from "moment";
import CommentIcon from "../../assests/comment.png";
import shareIcon from "../../assests/posts/share.png";
import SendIcon from "../../assests/posts/send.png";
import { AiOutlinePlus } from "react-icons/ai";
import { FaThumbsUp, FaReply } from 'react-icons/fa';
import { RiFileCopy2Line } from "react-icons/ri";
import CommentModal from "../commentModal/comment-modal";
import UserProfileImage from "../../assests/defaultImage.png";




const Post = ({ feed }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, createdAt, postUrl, caption, postId, likesCount, commentCount, backgroundColour, postLiked } = feed
  const { profilePhoto, firstName, lastName } = user
  const [isLike, setIsLike] = useState(postLiked)
  const [likeNum, setLikeNum] = useState(likesCount)
  // State to store replied comments
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [repliedComments, setRepliedComments] = useState({});
  const [likedComments, setLikedComments] = useState([]);
  const inputRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [{ }, setPostUrl] = useState('');

  console.log(caption)
  const handleCopyLink = () => {
    inputRef.current.select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const handleShareIconClick = () => {
    setShowLinkInput(true);
    setPostUrl(feed.postUrl); // Set the post URL when the share icon is clicked
    setTimeout(() => {
      setShowLinkInput(false);
      setPostUrl(''); // Clear the post URL after 3 seconds
    }, 3000);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  const handleCommentSubmit = (commentId) => {
    if (commentText.trim() !== "") {
      const replyId = "reply_" + Math.random().toString(36).substr(2, 9);
      const newReply = {
        id: replyId,
        user: {
          username: "Your Username",
          profileImage: UserProfileImage,
        },
        text: commentText,
        time: new Date(),
      };
      setRepliedComments({
        ...repliedComments,
        [commentId]: [...(repliedComments[commentId] || []), newReply],
      });
      setCommentText("");
    }
  };

  const handleLikeComment = (commentId) => {
    setLikedComments((prevLikedComments) =>
      prevLikedComments.includes(commentId)
        ? prevLikedComments.filter((id) => id !== commentId)
        : [...prevLikedComments, commentId]
    );
  };

  const handleReplyComment = (commentId, username) => {
    setCommentText("@" + username + " ");
  };


  const handleCommentIconClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const [comments, setComments] = useState([
    {
      id: 1,
      user: {
        username: "user1",
        profileImage: "https://thumbs.dreamstime.com/b/art-6260031.jpg"
      },
      text: "This is the first comment.",
      time: "2024-03-08T12:00:00",
      liked: false,
      likes: 0
    },
    {
      id: 2,
      user: {
        username: "user2",
        profileImage: "https://thumbs.dreamstime.com/z/alcohol-ink-texture-fluid-abstract-background-art-design-131606737.jpg?ct=jpeg"
      },
      text: "Second comment here!",
      time: "2024-03-08T12:05:00",
      liked: false,
      likes: 0
    },
    {
      id: 3,
      user: {
        username: "user3",
        profileImage: "https://img.freepik.com/free-photo/abstract-nature-painted-with-watercolor-autumn-leaves-backdrop-generated-by-ai_188544-9806.jpg"
      },
      text: "Another comment from user3.",
      time: "2024-03-08T12:10:00",
      liked: false,
      likes: 0
    },
    {
      id: 4,
      user: {
        username: "user4",
        profileImage: "https://ng.jumia.is/unsafe/fit-in/680x680/filters:fill(white)/product/97/3141531/1.jpg?3304"
      },
      text: "Yet another comment from user4.",
      time: "2024-03-08T12:15:00",
      liked: false,
      likes: 0
    },
    {
      id: 5,
      user: {
        username: "user5",
        profileImage: "https://thumbs.dreamstime.com/b/art-6260031.jpg"
      },
      text: "Fifth comment from user5.",
      time: "2024-03-08T12:20:00",
      liked: false,
      likes: 0
    },
    {
      id: 6,
      user: {
        username: "user6",
        profileImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR07x_Q_mh9jn-MozFnPwEwE3vt6h-5rWODvQtVBdHkFiA0ksTF6_dMJ5QwXUyQ9bsugAU&usqp=CAU"
      },
      text: "Sixth comment from user6.",
      time: "2024-03-08T12:25:00",
      liked: false,
      likes: 0
    },
    // Add more comments as needed
  ]);



  const profileImage = profilePhoto && profilePhoto.length > 0 ? profilePhoto[0].photoPublicUrl : '';

  // const selectedImages = [Image1, Image2, Image3, Image4, Image5];

  const remainingImages = postUrl.length - 2;

  const handleLikePost = async () => {
    try {
      if (isLike) {
        setIsLike(false)
        if (likeNum > 0) {
          setLikeNum(num => num - 1)
        }
        await dispatch(unlikePost(postId)).unwrap()
      }
      else {
        setIsLike(true)
        setLikeNum(num => num + 1)
        await dispatch(likePost(postId)).unwrap()
      }
    }
    catch (error) {
      console.log('like failed ', error.message)
    }
  }

  const handleViewPost = (index) => {
    dispatch(setViewPostIndex(index))
    dispatch(setViewPostUrl(postUrl))
    dispatch(setViewPostId({ postId, likeCount: likeNum }))
    navigate('/view-post')
  }


  const [showAllComments, setShowAllComments] = useState(false);
  const initialCommentsToShow = showAllComments ? comments.length : 5;
  const initialDisplayedComments = comments.slice(0, initialCommentsToShow);
  const [displayedComments, setDisplayedComments] = useState(initialDisplayedComments);

  const handleShowMoreComments = () => {
    setShowAllComments(true);
    setDisplayedComments(comments);
  };

  const CommentItem = ({ index, comment }) => {

    return (
      <div key={index} className="flex items-center mb-2">
        <Link to={`/profile/${comment.user.username}`}>
          <img src={comment.user.profileImage} alt="Profile" className="w-6 h-6 rounded-full" />
        </Link>
        <p className="ml-2 text-sm">{comment.text}</p>
        {/* Like text */}
        <button
          className={`ml-auto mr-2 cursor-pointer ${likedComments.includes(comment.id) ? 'text-yellow-200' : 'text-gray-500'}`}
          onClick={() => handleLikeComment(comment.id)}
        >
          {likedComments.includes(comment.id) ? "Liked" : "Like"}
        </button>
        {/* Reply text */}
        <button
          className="mr-2 cursor-pointer text-gray-500"
          onClick={() => handleReplyComment(comment.id, comment.user.username)}
        >
          Reply
        </button>
        <span className="text-xs text-white">{comment.likes} Likes</span>
        <span className="text-xs text-gray-500 ml-auto">{moment(comment.time).fromNow()}</span>
      </div>
    )
  }


  return (
    <div className="text-white bg-[#353530] rounded-[1rem] px-5 py-4">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center">
            <div className="w-12 h-12 hover:opacity-90 hover:scale-105 transition duration-100">
              <img src={profileImage || Profile} alt="Profile" className="rounded-full" />
            </div>
            <div className="ml-3 hover:opacity-80 hover:scale-105 transition duration-100 text-outfit">
              <p className="font-bold text-sm">{firstName} {lastName}</p>
              <div className="flex">
                <p className="text-xs">{moment(createdAt).fromNow()}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            src={DownIcon}
            alt="DownIcon"
            className="w-3 hover:opacity-80 hover:scale-105 transition duration-100"
          />
        </div>
      </div>
      {/* Image */}
      {postUrl.length > 0 ? (
        <div className=" grid grid-cols-2 gap-2">
          {postUrl.slice(0, 2).map((image, index) => (
            <div key={index} className={(postUrl.length === 1) ? "relative col-span-2" : (index === 1 && postUrl.length > 2 ? "relative" : "")}>
              <div onClick={() => handleViewPost(index)}>
                <img
                  src={image.postUrl}
                  alt={`Post ${index + 1}`}
                  className={`w-full mt-2 rounded h-84 ${postUrl.length === 1 ? "col-span-2" : ""}`}
                />
              </div>
              {index === 1 && postUrl.length > 2 && (
                <>
                  <div className="absolute inset-0 bg-black opacity-70  rounded"></div>
                  <button onClick={() => handleViewPost(index)} className="absolute inset-0 flex justify-center items-center">
                    <AiOutlinePlus className="text-white text-2xl cursor-pointer" />
                    <span className="absolute top-1/2 left-1/2 mx-4 transform -translate-x-1/2 -translate-y-1/2 text-xl font-light">{remainingImages}</span>
                  </button>
                </>
              )}
            </div>
          ))}
          <div className="col-span-2">
            <p className="overflow-hidden overflow-ellipsis">{caption}</p>
          </div>
        </div>


      ) :
        (
          <div className="w-full mt-4">
            <text
              className="w-full block text-center"
              style={{
                backgroundColor: backgroundColour,
                height: "280px", // Adjust height to match the height of the image
                display: "flex",
                justifyContent: "center", // Align content horizontally to the center
                alignItems: "center", // Align content vertically to the center
                padding: "0.5rem" // Optional: Add padding for better appearance
              }}
            >
              {caption}
            </text>
            {showLinkInput && (
              <div className="flex items-center w-full p-3 pt-4">
                <div className="flex bg-[#1C1B19] text-sm rounded-full items-center ml-2 w-full">
                  <input
                    ref={inputRef}
                    type="text"
                    value={postUrl}
                    readOnly
                    className="outline-0 bg-[#1C1B19] p-2 rounded-l-full w-full font-outfit text-white"
                  />
                  <button onClick={handleCopyLink} className="flex gap 2">
                    <RiFileCopy2Line className={`text-xl ${copied ? 'text-yellow-500' : 'text-white'}`} />
                    {copied && <span className="ml-1">Copied!</span>}
                  </button>
                </div>
              </div>
            )}
          </div>
        )
      }
      {/* Number of Likes + Buttons */}
      <div>
        <div className="items-center text-[#8e8d8d font-outfit]">
          <div className="flex items-center py-2 justify-between">
            <div className="flex items-center gap-2">
              <button className="w-[1.1rem] h-[1.1rem]">

                <FaThumbsUp
                  className={`hover:opacity-80 hover:scale-105 transition duration-100 ${isLike ? 'text-yellow-500' : 'text-white'}`}
                  onClick={handleLikePost}
                />

              </button>
              <p className="text-xs font-outfit">{likeNum} likes</p>
              <button
                className="w-[1.1rem] h-[1.1rem]"
                onClick={handleCommentIconClick}
              >
                <img
                  src={CommentIcon}
                  alt="CommentIcon"
                  className="hover:opacity-80 hover:scale-105 transition duration-100"
                />
              </button>

              {/* Modal */}
              {isModalOpen && (
                <CommentModal
                  postUrl={feed.postUrl}
                  comments={comments}
                  onClose={handleCloseModal}
                  postId={postId}
                />
              )}
              <p className="text-xs font-outfit">{commentCount} comments</p>
            </div>
            <div>
              <div className="w-[1.1rem] h-[1.1rem]" onClick={handleShareIconClick}>
                <img
                  src={shareIcon}
                  alt="ShareIcon"
                  className="hover:opacity-80 hover:scale-105 transition duration-100"
                />
              </div>



            </div>
          </div>
        </div>


        {/* Comments */}
        <div className="text-xs font-outfit mt-2">
          {/* {comments.map((comment, index) => (
            <CommentItem key={index} index={index} comment={comment} />
          ))} */}
          {/* Show more comments button */}
          {/* {comments.length > 5 && !showAllComments && (
            <div className="text-sm cursor-pointer text-gray-500" onClick={handleShowMoreComments}>
              Show more
            </div>
          )} */}
          {/* Comment Input */}
          <div className="flex items-center w-full p-3 pt-4">
            <Link to="/profile" className="w-8 h-8 shrink-0">
              <img src={profileImage} alt="Profile" className="rounded-full" />
            </Link>
            <div className="flex bg-[#1C1B19] font-outfit text-sm rounded-full items-center ml-2 w-full">
              <input
                type="text"
                placeholder="Add a comment..."
                className="outline-0 bg-[#1C1B19] p-1 pl-3 rounded-full w-full font-outfit"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <button onClick={handleCommentSubmit}>
                <img src={SendIcon} alt="SendIcon" className="w-10" />
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

const MyPost = () => {
  const dispatch = useDispatch()
  const { userData } = useSelector((state) => state.user)
  const { myPost } = useSelector((state) => state.post)

  useEffect(() => {
    if (userData && userData.userId) {
      dispatch(getMyPost(userData.userId))
    }
  }, [userData])

  return (
    <div className="w-screen sm:w-full">
      <div className="my-6 max-w-[40rem] grid grid-cols-1 gap-6 sm:max-w-[40rem]">
        {myPost && myPost.length > 0 &&
          myPost.map((feed, index) => (
            <Post key={feed.postId} feed={feed} />
          ))
        }
      </div>
    </div>
  );
};

export default MyPost;

