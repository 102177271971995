import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";

export const signup = createAsyncThunk("auth/signup", async (formData) => {
  try {
    const request = await fetch(`${apiUrl}/api/user/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const response = await request.json();

    if (response.status === "success") {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
});

export const login = createAsyncThunk("auth/login", async (data) => {
  try {
    const request = await fetch(`${apiUrl}/api/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const response = await request.json();
    console.log(response);
    if (response.status === "success") {
      console.log(response.user);
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
});

export const sendotp = createAsyncThunk('auth/otp', async (data) => {
  try {
    const request = await fetch(`${apiUrl}/api/user/otp`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
    const response = await request.json()
    if (response.status === 'success') {
      console.log(response)
      return response
    }
    else {
      throw new Error(response.message)
    }
  }
  catch (error) {
    throw new Error(error.message)
  }
})

export const verifyotp = createAsyncThunk('auth/verify-otp', async (data) => {
  try {
    const request = await fetch(`${apiUrl}/api/user/verifyotp`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
    const response = await request.json()
    if (response.status === 'success') {
      console.log(response)
      return response
    }
    else {
      throw new Error(response.message)
    }
  }
  catch (error) {
    throw new Error(error.message)
  }
})

export const resetPassword = createAsyncThunk('auth/reset-password', async (data) => {
  try {
    console.log(JSON.stringify(data))
    const request = await fetch(`${ apiUrl }/api/user/passwordrecovery`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const response = await request.json()
    if (response.status === 'success') {
      console.log('reset password API: ', response)
      return response
    }
    else {
      throw new Error(response.message)
    }
  }
  catch (error) {
    throw new Error(error.message)
  }
})
