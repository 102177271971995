import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaThumbsUp, FaThumbtack } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getMyGroups } from "../../redux/group/groupAction";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import moment from "moment";




const GroupItem = ({ group }) => {
    const { avatarUrl, bannerPhotoUrl, name, memberCount, groupId, pinned } = group ?? {}
    const navigate = useNavigate();

    return (
        <div className="groupL4 p-3 ">
            <div className="flex justify-between">
                <div className="flex gap-3">
                    <div className="rounded-xl w-32 h-30 bg-[#353532] flex inset-0 justify-center items-center">
                        {!bannerPhotoUrl ?
                            <img src={"images/3-User.png"} alt="user" className="w-20" />
                            :
                            <img src={bannerPhotoUrl} alt="" className="w-full h-full rounded-xl" />
                        }
                    </div>
                    <div className="mt-12">
                        <h1 className="text-[15px] ">{name}</h1>
                        <h2 className="text-[13px] mt-2"> {memberCount || 1} Members in the group</h2>
                    </div>
                </div>
                {pinned && <div>
                    <FaThumbtack className="" style={{ color: "#BE9524" }} />
                </div>}
            </div>

            <div className="flex items-center mt-3 flex-1">
                <button
                    onClick={() => navigate(`/viewgroup/${groupId}`)}
                    className="rounded-[5px] h-9 flex-1 flex items-center justify-center text-[14px] text-white bg-[#1C1B19]"
                >
                    View Groups
                </button>
                <img
                    src="images/dotDrop.png"
                    alt="dotDrop"
                    className="w-9 h-9 ml-3"
                />
            </div>
        </div>
    )
}

const nullArray = new Array(3).fill(null)


const GroupRender = () => {
    const dispatch = useDispatch();
    const [selectedMenu, setSelectedMenu] = useState("My Group");
    const [isCreate, setIsCreate] = useState(false);
    const { myGroup } = useSelector((state) => state.group)

    useEffect(() => {
        if (myGroup.length === 0) {
            fetchMyGroup()
        }
    }, [])

    const sortedGroups = [...myGroup].sort((a, b) => {
        // If "a" is pinned and "b" is not pinned, "a" should come first
        if (a.pinned && !b.pinned) {
            return -1;
        }
        // If "b" is pinned and "a" is not pinned, "b" should come first
        else if (!a.pinned && b.pinned) {
            return 1;
        }
        // Otherwise, maintain the current order
        else {
            return 0;
        }
    });

    const fetchMyGroup = async () => {
        try {
            await dispatch(getMyGroups()).unwrap()
        }
        catch (error) {
            console.log('error getting my groups', error.message)
        }
    }

    const toggleCreate = () => {
        setIsCreate(!isCreate);
    };

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
    };

    return (
        <div className="flex flex-col gap-4 mt-4">
            {myGroup.length === 0 ? (
                <div className="flex justify-center mt-[4%] items-center">
                    <div className="groupBackground text-center ">
                        <div className="mt-[10%]">
                            <div className="flex justify-center items-center">
                                <img src="images/group.png" alt="group" className="w-28" />
                            </div>
                            <h1>You haven’t joined any groups yet</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="p-7 mt-2">
                    <div className="bg-[#353530] pb-2 p-2 rounded-2xl h-auto overflow-y-hidden" style={{
                        maxHeight: 'calc(100vh - 4rem)',
                        overflowY: 'scroll',
                        scrollbarWidth: "none",
                        WebkitOverflowScrolling: "touch", // For iOS devices
                    }}>
                        <h1 className="mx-4">All the groups you are an admin ({myGroup.length})</h1>
                        {/* <div className="flex inset-0 justify-center"> */}
                        <div className="grid grid-cols-2 gap-4 mt-4 items-center ">
                            {sortedGroups.map((group) => (
                                <GroupItem key={group.groupId} group={group} />
                            ))}
                        </div>
                        {/* </div> */}
                    </div></div>
            )}
        </div>
    );
};

export default GroupRender;
