import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authReducer";
import userReducer from "./user/userReducer";
import usersReducer from "./other-users/usersReducer";
import friendReducer from "./friend/friendReducer";
import storyReducer from "./story/storyReducer";
import postReducer from "./post/postReducer";
import commentReducer from "./comment/commentReducer";
import reelsReducer from "./reels/reelsReducer";
import groupReducer from "./group/groupReducer";
import eventReducer from "./event/eventReducer";
import albumReducer from './album/albumReducer'

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    friend: friendReducer,
    story: storyReducer,
    post: postReducer,
    comment: commentReducer,
    reels: reelsReducer,
    group: groupReducer,
    event: eventReducer,
    album: albumReducer
  },
});

export default store;
