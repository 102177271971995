import "./style.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyFriends } from "../../redux/user/userAction";
import profileImage from "../../assests/defaultImage.png";
import { FaAngleDown, FaAngleUp, FaUserTimes } from "react-icons/fa";

const RightSideFriend = () => {
  const dispatch = useDispatch();
  const { myFriends } = useSelector((state) => state.user);

  const [visibleFriends, setVisibleFriends] = useState(4);

  const loadMoreFriends = () => {
    setVisibleFriends((prevVisibleFriends) => prevVisibleFriends + 4);
  };

  const seeLessFriends = () => {
    setVisibleFriends(4);
  };

  useEffect(() => {
    dispatch(getMyFriends());
  }, [dispatch]);

  return (
    <div className="mr-5 text-white">
      <div className="flex justify-end">
        <img src="images/setting.png" alt="settings" className="w-8" />
      </div>
      <div className="friendBackground">
        <div className="">
          <div className="flex items-center gap-2 text-sm">
            <img src="images/frIcon.png" alt="friends icon" className="w-7" />
            Pals
          </div>
          <div className="border-b mt-2"></div>
          <div className="flex gap-2 mt-2">
            <button
              type="submit"
              className="rounded-xl mt-2 px-4 pb-1 pt-1 text-[16px] text-black bg-[#BE9524]"
            >
              All Pals
            </button>
          </div>
        </div>
        <div className="mt-4">
          {myFriends.length === 0 ? (
            <p className="text-center text-white text-xl">
              You don't have any Pals yet.
            </p>
          ) : (
            <>
              <div className="grid grid-cols-4 items-center text-center gap-3 animate-fade-in">
                {myFriends.slice(0, visibleFriends).map((friend) => (
                  <div className="FriendBg items-center p-2" key={friend.userId}>
                    <div className="relative">
                      <img
                        src={
                          friend.profilePhoto.length > 0
                            ? friend.profilePhoto[0].photoPublicUrl
                            : profileImage
                        }
                        alt={`${friend.firstName} profile pic`}
                        className="w-24 h-24 mx-auto rounded-full"
                      />
                      <h1 className="text-[13px] font-bold font-outfit mt-2">
                        {friend.firstName} {friend.lastName}
                      </h1>
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="rounded-xl mt-2 w-28 px-4 pb-1 pt-1 text-[12px] bg-[#BE9524] text-black flex items-center justify-center"
                        >
                          <FaUserTimes className="w-4 h-4 mr-3" /> Unfriend
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {myFriends.length > visibleFriends && (
                <button
                  onClick={loadMoreFriends}
                  className="flex items-center px-4 pb-2 py-2 mt-4 bg-[#262522] text-sm rounded-full"
                >
                  Load More Friends
                  <FaAngleDown className="w-5 h-5 ml-2" />
                </button>
              )}

              {visibleFriends > 4 && (
                <button
                  onClick={seeLessFriends}
                  className="flex items-center px-4 pb-2 py-2 mt-4 bg-[#262522] text-sm rounded-full"
                >
                  See Less Friends
                  <FaAngleUp className="w-5 h-5 ml-2" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RightSideFriend;
 