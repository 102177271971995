import { createAsyncThunk } from "@reduxjs/toolkit";
// import { showMessage } from "react-native-flash-message";
import { toast } from "react-toastify";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";
// import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
// import font from "../../constant/font";
// import colors from "../../constant/colors";

export const getAllReels = createAsyncThunk('reels/get-all', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/all`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('reel response', response)
        if (response.status === 'success') {
            return response.reels
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getFriendsReels = createAsyncThunk('reels/get-friends', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/friends`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('freinds reel response', response)
        if (response.status === 'success') {
            return response.reels
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const searchReels = createAsyncThunk('reels/search', async (searchQuery) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/search${searchQuery}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        const response = await request.json()
        console.log('searched reels; ', response)
        if (response.status === 'success') {
            return response.reels
        } else {
            console.log('error searching reels', response.message)
        }
    }
    catch (error) {
        console.log('error searching reels', error.message)
    }
})

export const getReelsSearchPreview = createAsyncThunk('reels/search/preview', async () => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/preview`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        const response = await request.json()
        console.log('reels search preview; ', response)
        if (response.status === 'success') {
            return response.reels
        } else {
            console.log('error loading reel search preview', response.message)
        }
    }
    catch (error) {
        console.log('error loading reel search preview', error.message)
    }
})

export const uploadReels = createAsyncThunk('reels/upload', async ({ file, caption, type }) => {

    // type can be 'public', 'private' or 'friends'

    try {
        const token = await getAccessToken()

        const formData = new FormData()

        formData.append('video', file )
        formData.append('caption', caption)
        formData.append('type', type)

        // showMessage({
        //     message: 'uploading reels...',
        //     icon: () => (
        //         <View style={{ justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
        //             <MaterialIcons name="live-tv" color={'white'} size={18} />
        //         </View>
        //     ),
        //     hideOnPress: false,
        //     type: 'default',
        //     textStyle: { fontFamily: font.textFont, fontSize: 18 },
        //     floating: true,
        //     autoHide: false,
        // })

        const request = await fetch(`${apiUrl}/file/api/reels`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                // Accept: 'application/json',
                // 'Content-Type': 'multipart/form-data',
            },
            body: formData
        })

        const response = await request.json()
        console.log('reels response; ', response)
        if (response.status === 'success') {
            toast.success("Reels uploaded successfully!", {
                position: 'bottom-left'
            });
            return response.post
        } else {
            toast.warning(`Reels uplaod failed ${response.message}` );
        }
    }
    catch (error) {
        console.log('error uploading reels', error.message)
        toast.warning(`Reels uplaod failed ${error.message}` );
    }
});

export const getMyReels = createAsyncThunk('reels/personal-reels', async (userId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/file/api/reels/${userId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
        const response = await request.json()
        console.log('get my reels response; ', response)
        if (response.status === 'success') {
            return response
        } else {
            console.log('error fetching personal reels', response.message)
        }
    }
    catch (error) {
        console.log('error fetching personal reels', error.message)
    }
})

export const likeReels = createAsyncThunk('reels/like', async (reelId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/${reelId}/like`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        if (response.status === 'success') {
            return response.message
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const unlikeReels = createAsyncThunk('reels/unlike', async (reelId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/${reelId}/unlike`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        if (response.status === 'success') {
            return response.message
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const saveReels = createAsyncThunk('reels/save', async ({reelId, reel}) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/save-reels/${reelId}/save`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('save reels: ', response)
        if (response.status === 'success') {
            toast.success("Reels saved successfully!");
            return {message: response.message, reel}
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const unsaveReels = createAsyncThunk('reels/unsave', async (reelId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/save-reels/${reelId}/unsave`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('unsave reels: ', response)
        if (response.message === 'Reel unsaved successfully') {
            toast.success("Reels unsaved!");
            return reelId
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getSavedReels = createAsyncThunk('reels/get-saved-reels', async (_, {getState}) => {
    try {
        const state = getState()
        const {userData} = state.user
        const { userId } = userData ?? {}
        // console.log('my Id; ', userId)
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/save-reels/${userId}/saved-reels`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('gotten saved reels response: ', response)
        if (response.status === 'success') {
            return response.savedReels
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getReelsComment = createAsyncThunk('reels/get-comment', async ({ reelId }) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/comments/${reelId}/1`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('reels comment: ', response)
        if (response.status === 'success') {
            return response.data
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const postReelsComment = createAsyncThunk('reels/comment', async ({ reelId, comment }) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/comments/${reelId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(comment)
        })
        const response = await request.json()
        console.log('post reels comment: ', response)
        if (response.status === 'success') {
            return response.comment
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const deleteReelsComment = createAsyncThunk('reels/comment/delete', async (commentId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/comments/${commentId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('deleted reels comment: ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const likeReelsComment = createAsyncThunk('reels/comment/like', async (commentId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/comments/${commentId}/like`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('liked reels comment: ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const unlikeReelsComment = createAsyncThunk('reels/comment/unlike', async (commentId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/comments/${commentId}/unlike`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('unliked reels comment: ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const getReelsCommentReplies = createAsyncThunk('reels/comment/replies', async (commentId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/comments/${commentId}/replies/1`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('reels comment replies: ', response)
        if (response.message === 'replies gotten successfully') {
            return response.replies
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const replyReelsComment = createAsyncThunk('reels/comment/replies/create', async ({ commentId, reply }) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/comments/${commentId}/reply/reply`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reply)
        })
        const response = await request.json()
        console.log('reels reply response: ', response)
        if (response.message === 'Reply created successfully') {
            return response.reply
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

export const deleteReelsCommentReplies = createAsyncThunk('reels/comment/replies/create', async (replyId) => {
    try {
        const token = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/reels/comments/${replyId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const response = await request.json()
        console.log('reels reply deleted: ', response)
        if (response.status === 'success') {
            return response.message
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
})