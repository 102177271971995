import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";

export const getUserDetails = createAsyncThunk(
  "users/details",
  async (userId) => {
    try {
      const token = await getAccessToken();

      const request = await fetch(
        `${apiUrl}/api/friends/friendship-status-user-details/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const response = await request.json();
      if (response.message === "User found") {
        const newData = {
          ...response.user,
          friendshipStatus: response.friendshipStatus.message,
        };
        console.log("user by id details; ", newData);
        return newData;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
