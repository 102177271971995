import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "../../helper/tokenStorage";
import { createGroup, getMyGroups, getJoinedGroups, getSuggestedGroups, searchGroup, pinGroup, unpinGroup } from "./groupAction";
import { apiUrl } from "../../helper";


export const addContentToExistingGroups = (incomingContent, existingArray) => {
    incomingContent.forEach((item) => {

        const existingIndex = existingArray.findIndex((existingItem) =>
            existingItem.groupId === item.groupId
        );

        if (existingIndex !== -1) {
            existingArray[existingIndex] = item;
        } else {
            existingArray.push(item);
        }
    });
};


const getGroupDetails = async (groupId, token) => {
    try {
        const request = await fetch(`${apiUrl}/api/chat/group/${groupId}/details`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        const response = await request.json()
        if (response.status === 'success') {
            return response.group
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
};

export const getGroupMembers = async (groupId, token) => {
    try {
        const request = await fetch(`${apiUrl}/api/chat/get-group-members/${groupId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        const response = await request.json()
        console.log('members; ', response)
        if (response.status === 'success') {
            return response
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
};

const fetchGroupPosts = async (groupId, token) => {
    try {
        const response = await fetch(`${apiUrl}/api/group/post/group-posts/${groupId}?page=1&limit=15`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const responseData = await response.json();
        // console.log('ge Post', responseData)
        if (responseData.status === 'success') {
            return responseData.posts;
        } else {
            throw new Error(responseData.message);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};


export const fetchGroupProfile = createAsyncThunk('group-profile', async (groupId, { dispatch }) => {
    try {
        const token = await getAccessToken()
        const groupInfo = await getGroupDetails(groupId, token)
        dispatch(updateGroupProfile(groupInfo));
        // console.log(groupInfo)

        let groupMembers = {};
        try {
            groupMembers = await getGroupMembers(groupId, token)
            dispatch(updateGroupMember({ groupId, groupMembers }));
        }
        catch (error) {
            console.log('unable to fecth group member', error.member)
        }

        let groupPosts = [];
        try {
            groupPosts = await fetchGroupPosts(groupId, token)
            dispatch(updateGroupPosts({ groupId, groupPosts }));

        } catch (error) {
            console.log('error fetching post', error.message)
        }

        return { ...groupInfo, groupPosts: groupPosts, groupMembers: groupMembers };
    }
    catch (error) {
        console.error('Error fetching user profile:', error.message);
    }
});

const initialState = {
    profiles: {}, // Object to store user profiles

    creating: false,

    loadingMyGroups: false,
    myGroup: [],

    loadingJoinedGroup: false,
    joinedGroup: [],

    pinningGroup: false,


    searchQuerry: '',
    searching: false,
    groupSearchHistory: [],
    searchError: '',

    loadingSuggestedGroup: false,
    suggestedGroup: [],

    loading: false,
    error: null,
};

const groupSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        setGroupSearchQuerry(state, action) {
            state.searchQuerry = action.payload
        },
        updateGroupInformation(state, action) {
            const { groupId, data } = action.payload
            if (state.profiles[groupId]) {
                console.log('mini edit ', action.payload)
                // Update the group by merging existing data with new info
                state.profiles[groupId] = {
                    ...state.profiles[groupId],
                    ...data
                };
            }

            const index = state.myGroup.findIndex(group => group.groupId === groupId);

            // Update the group in the myGroups array if found
            if (index !== -1) {
                state.myGroup[index] = {
                    ...state.myGroup[index],
                    ...data
                };
            }

        },
        updateGroupPinnedStatus(state, action) {
            const { groupId, pinnedUpdate } = action.payload
            if (state.profiles[groupId]) {
                console.log('mini edit ', action.payload)
                // Update the group by merging existing data with new info
                state.profiles[groupId] = {
                    ...state.profiles[groupId],
                    isPinned: pinnedUpdate
                };
            }

            const myIndex = state.myGroup.findIndex(group => group.groupId === groupId);

            // Update the group in the myGroups array if found
            if (myIndex !== -1) {
                state.myGroup[myIndex] = {
                    ...state.myGroup[myIndex],
                    pinned: pinnedUpdate
                };
            }

            const joinedIndex = state.joinedGroup.findIndex(group => group.groupId === groupId);

            if (joinedIndex !== -1) {
                state.joinedGroup[joinedIndex] = {
                    ...state.joinedGroup[joinedIndex],
                    pinned: pinnedUpdate
                };
            }

        },
        updateGroupProfile(state, action) {
            const userInfo = action.payload;
            state.profiles[userInfo.groupId] = { ...state.profiles[userInfo.groupId], ...userInfo };
        },
        updateGroupMember(state, action) {
            const { groupId, groupMembers } = action.payload;
            state.profiles[groupId].groupMembers = groupMembers;
        },
        updateGroupPosts(state, action) {
            const { groupId, groupPosts } = action.payload;
            state.profiles[groupId].groupPosts = groupPosts;
        },
        setActivePost(state, action) {
            const { groupId, postId } = action.payload;
            const userProfile = state.profiles[groupId];
            // console.log('this as ben done; ', action.payload)
            if (userProfile) {
                const postToUpdate = userProfile.posts.find(post => post.postId === postId);
                if (postToUpdate) {
                    postToUpdate.activePost = true;
                    userProfile.posts.forEach(post => {
                        if (post.postId !== postId) {
                            post.activePost = false;
                        }
                    });
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGroupProfile.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchGroupProfile.fulfilled, (state, action) => {
                state.loading = false
                const groupProfile = action.payload
                state.profiles[groupProfile.groupId] = groupProfile
            })
            .addCase(fetchGroupProfile.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

        builder
            .addCase(createGroup.pending, (state) => {
                state.creating = true
            })
            .addCase(createGroup.fulfilled, (state, action) => {
                state.creating = false
                state.myGroup.unshift(action.payload)
            })
            .addCase(createGroup.rejected, (state, action) => {
                state.creating = false
                state.error = action.error.message
            })

        builder
            .addCase(searchGroup.pending, (state) => {
                state.searching = true
            })
            .addCase(searchGroup.fulfilled, (state, action) => {
                state.searching = false
                addContentToExistingGroups(action.payload, state.groupSearchHistory)
                // addContentToExistingGroups(action.payload, state.groupSearchHistory)
            })
            .addCase(searchGroup.rejected, (state, action) => {
                state.searching = false
                state.searchError = action.error.message
            })

        builder
            .addCase(getMyGroups.pending, (state) => {
                state.loadingMyGroups = true
            })
            .addCase(getMyGroups.fulfilled, (state, action) => {
                state.loadingMyGroups = false
                state.myGroup = action.payload
            })
            .addCase(getMyGroups.rejected, (state, action) => {
                state.loadingMyGroups = false
                state.error = action.error.message
            })

        builder
            .addCase(pinGroup.pending, (state) => {
                state.pinningGroup = true
            })
            .addCase(pinGroup.fulfilled, (state) => {
                state.pinningGroup = false
            })
            .addCase(pinGroup.rejected, (state) => {
                state.pinningGroup = false
            })

        builder
            .addCase(unpinGroup.pending, (state) => {
                state.pinningGroup = true
            })
            .addCase(unpinGroup.fulfilled, (state) => {
                state.pinningGroup = false
            })
            .addCase(unpinGroup.rejected, (state) => {
                state.pinningGroup = false
            })

        builder
            .addCase(getJoinedGroups.pending, (state) => {
                state.loadingJoinedGroup = true
            })
            .addCase(getJoinedGroups.fulfilled, (state, action) => {
                state.loadingJoinedGroup = false
                state.joinedGroup = action.payload
            })
            .addCase(getJoinedGroups.rejected, (state, action) => {
                state.loadingJoinedGroup = false
                state.error = action.error.message
            })

        builder
            .addCase(getSuggestedGroups.pending, (state) => {
                state.loadingSuggestedGroup = true
            })
            .addCase(getSuggestedGroups.fulfilled, (state, action) => {
                state.loadingSuggestedGroup = false
                state.suggestedGroup = action.payload
            })
            .addCase(getSuggestedGroups.rejected, (state, action) => {
                state.loadingSuggestedGroup = false
                state.error = action.error.message
            })
    }
});

export const { setActivePost, updateGroupPosts, updateGroupProfile, updateUserReels, updateGroupInformation, updateGroupMember, setGroupSearchQuerry, updateGroupPinnedStatus } = groupSlice.actions
export default groupSlice.reducer