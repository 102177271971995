import { createSlice } from "@reduxjs/toolkit";
import { createPost, deletePost, getUserFeed, getSavedPost, getMyPost, getUserPost, savePost, unsavePost } from "./postAction";

const initialState = {
    loading: false,

    viewPostIndex: 0,
    viewPostUrls: [],
    viewPostId: '',
    viewPostLikeCount: '',

    uploading: false,
    deleting: false,

    error: null,


    myPostLoading: false,
    myPost: [],

    userPostLoading: false,
    userPost: [],

    savedPostLoading: false,
    savedPosts: [],
    savedError: null,

    feedLoading: false,
    feed: []
}

const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        setViewPostIndex: (state, action) => {
            state.viewPostIndex = action.payload
        },

        setViewPostUrl: (state, action) => {
            state.viewPostUrls = action.payload
        },

        setViewPostId: (state, action) => {
            state.viewPostId = action.payload.postId
            state.viewPostLikeCount = action.payload.likeCount
        },
    },

    extraReducers: (builder) => {

        builder
            .addCase(createPost.pending, (state) => {
                state.uploading = true
            })
            .addCase(createPost.fulfilled, (state, action) => {
                state.uploading = false
                state.uploadStatus = 'success'
            })
            .addCase(createPost.rejected, (state, action) => {
                state.uploading = false
                state.uploadStatus = 'failed'
                state.error = action.error.message
            })

        builder
            .addCase(deletePost.pending, (state) => {
                state.deleting = true
            })
            .addCase(deletePost.fulfilled, (state, action) => {
                state.deleting = false
            })
            .addCase(deletePost.rejected, (state, action) => {
                state.deleting = false
                state.error = action.error.message
            })

        builder
            .addCase(getUserFeed.pending, (state) => {
                state.feedLoading = true
            })
            .addCase(getUserFeed.fulfilled, (state, action) => {
                state.feedLoading = false
                state.feed = action.payload
            })
            .addCase(getUserFeed.rejected, (state, action) => {
                state.feedLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(getMyPost.pending, (state) => {
                state.myPostLoading = true
                state.myPost = []
            })
            .addCase(getMyPost.fulfilled, (state, action) => {
                state.myPostLoading = false
                state.myPost = action.payload
            })
            .addCase(getMyPost.rejected, (state, action) => {
                state.myPostLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(getUserPost.pending, (state) => {
                state.userPostLoading = true
                state.userPost = []
            })
            .addCase(getUserPost.fulfilled, (state, action) => {
                state.userPostLoading = false
                state.userPost = action.payload
            })
            .addCase(getUserPost.rejected, (state, action) => {
                state.userPostLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(savePost.fulfilled, (state, action) => {
                const { isSaved, post } = action.payload
                const postId = post.postId
                if (isSaved) {
                    const index = state.feed.findIndex(post => post.postId === postId)
                    if (index !== -1) {
                        state.feed[index] = {
                            ...state.feed[index],
                            saved: true
                        }
                    }
                    state.savedPosts.unshift(post)
                }
            })

        builder
            .addCase(unsavePost.fulfilled, (state, action) => {
                const { postId, unSaved } = action.payload
                if (unSaved) {
                    const index = state.feed.findIndex(post => post.postId === postId)
                    if (index !== -1) {
                        state.feed[index] = {
                            ...state.feed[index],
                            saved: false
                        }
                    }
                    const filteredSavedPost = state.savedPosts.filter(post => post.postId !== postId)
                    state.savedPosts = filteredSavedPost
                }
            })

        builder
            .addCase(getSavedPost.pending, (state) => {
                state.savedPostLoading = true
            })
            .addCase(getSavedPost.fulfilled, (state, action) => {
                state.savedPostLoading = false
                state.savedPosts = action.payload
            })
            .addCase(getSavedPost.rejected, (state, action) => {
                state.savedPostLoading = false
                state.error = action.error.message
            })
    }
})


export const { setViewPostIndex, setViewPostUrl, setViewPostId } = postSlice.actions
export default postSlice.reducer