import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "../../helper/tokenStorage";
import { toast } from "react-toastify";
import { apiUrl } from "../../helper";

export const getUser = createAsyncThunk("user", async (accessToken) => {
  try {
    const request = await fetch(`${apiUrl}/api/user/user-details`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const response = await request.json();
    // console.log("user response; ", response);
    if (response.status === "success") {
      console.log("user response; ", response.user);
      return response.user;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
});

export const updateUser = createAsyncThunk(
  "user/update",
  async (data) => {
    try {
      const accessToken = await getAccessToken()
        const request = await fetch(`${apiUrl}/api/user/profileupdate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(data)
        })
        const response = await request.json()
        console.log('edit user profile response', response)
        if (response.status === 'success') {
            return response.data
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
  }
);

export const getMyFriends = createAsyncThunk('user/friends', async () => {
  try {
    const token = await getAccessToken()

    const request = await fetch(`${apiUrl}/api/friends/my-friends`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })

    const response = await request.json()
    console.log('my friends; ', response)
    if (response.status === "success") {
      const friends = response.personalFriends
      console.log('my friends', friends)
      return friends
    } else {
      throw new Error(response.message)
    }
  }
  catch (error) {
    throw new Error(error.message)
  }
})

export const getMyFilteredFriends = createAsyncThunk('user/filtered-friends', async (data) => {
  console.log(JSON.stringify(data))
  try {
    const token = await getAccessToken()

    const request = await fetch(`${apiUrl}/api/friends/filtered-friends`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    })

    const response = await request.json()
    console.log('my filtered friends; ', response)
    // if (response.status === "success") {
    //   const friends = response.personalFriends
    //   // console.log('my friends', friends)
    //   return friends
    // } else {
    //   throw new Error(response.message)
    // }
  }
  catch (error) {
    throw new Error(error.message)
  }
})

export const uploadProfilePics = createAsyncThunk('upload/profile-pics', async (formData) => {
  try { 
      const token = await getAccessToken()
      const request = await fetch(`${apiUrl}/image/api/profilepic/profile`, {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${token}`,
          },
          body: formData
      })
      const response = await request.json()
      if (response.status === 'success') {
          const imageUrl = response.post.photoPublicUrl
          return imageUrl
      } else {
          throw new Error(response.message)
      }
  }
  catch (error) {
      throw new Error(error.message)
  }
})

export const uploadCoverPhoto = createAsyncThunk('upload/cover-pics', async (formData) => {
  try {
      const token = await getAccessToken()
      const request = await fetch(`${apiUrl}/image/api/cover/profile`, {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${token}`,
          },
          body: formData
      })
      const response = await request.json()
      console.log('cover response; ', response)
      if (response.status === 'success') {
          const imageUrl = response.data.photoPublicUrl
          toast.success("Cover photo uploaded successfully!");
          return imageUrl
      } else {
          throw new Error(response.message)
      }
  }
  catch (error) {
      throw new Error(error.message)
  }
})

export const deleteProfilePicture = createAsyncThunk(
  "profile/deletePicture",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const accessToken = getState().auth.accessToken; // Get access token from your auth state
      const response = await fetch(`/profilepic/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json(); // Parse the error response if available
        const errorMessage =
          errorData?.message || "Failed to delete profile picture";
        throw new Error(errorMessage);
      }

      return userId;
    } catch (error) {
      console.error("Delete Profile Picture Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
);
export const searchUsers = createAsyncThunk(
  "user/search",
  async ({ accessToken, searchQuery }) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/user/search/search-all-users?searchQuery=${searchQuery}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Response is not in JSON format");
      }

      const data = await response.json();
      if (data.status === "success") {
        console.log("search result", data.users);

        return data.users;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
