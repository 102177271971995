import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "../../helper/tokenStorage";
import { apiUrl } from "../../helper";

import {
    createEvent, getMyEvent, getInterestedEvent, getGoingEvent, getInvitedEvent, getSuggestedEvent, removeEventInvitation, searchEvent, getEventCategory 
} from "./eventAction";

export const addContentToExistingEvent = (incomingContent, existingArray) => {
    incomingContent.forEach((item) => {

        const existingIndex = existingArray.findIndex((existingItem) =>
            existingItem.eventId === item.eventId
        );

        if (existingIndex !== -1) {
            existingArray[existingIndex] = item;
        } else {
            existingArray.push(item);
        }
    });
};

const getEventDetails = async (eventId, token) => {
    try {
        const request = await fetch(`${apiUrl}/api/events/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        const response = await request.json()
        console.log('event details; ', response)
        if (response.status === 'success') {
            return response.event
        } else {
            throw new Error(response.message)
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
}

export const fetchEventProfile = createAsyncThunk('event/profile', async (eventId, { dispatch }) => {
    try {
        // console.log('hello')
        const token = await getAccessToken()
        const eventDetails = await getEventDetails(eventId, token)
        dispatch(updateEventInfo(eventDetails))

        return { ...eventDetails }
    }
    catch (error) {
        throw new Error(error.message)
    }
})

const initialState = {
    loading: false,
    creating: false,
    profiles: {},

    loadingCategory: false,
    eventCategory: {},
    categoryError: null,

    suggesedEventLoading: false,
    suggestedEvent: [],

    myEventLoading: false,
    myEvent: [],

    interestedEventLoading: false,
    interestedEvent: [],

    goinEventLoading: false,
    goingEvent: [],

    invitationLoading: false,
    invitaionEvents: [],

    searchQuerry: '',
    searching: false,
    eventSearchHistory: [],
    searchError: '',

    pastEventLoading: false,
    pastEvent: [],

    error: null
}

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        updateEventInfo(state, action) {
            const eventDetails = action.payload
            state.profiles[eventDetails.eventId] = { ...state.profiles[eventDetails.eventId], ...eventDetails }
        },
        updateInterestedEvent(state, action) {
            const { method, data } = action.payload
            const index = state.suggestedEvent.findIndex(event => event.eventId === data.eventId)

            if (method === 'add') {
                state.interestedEvent.push(data)

                if (state.profiles[data.eventId]) {
                    state.profiles[data.eventId].interestedStatus = true
                }

                if (index !== -1) {
                    state.suggestedEvent[index].interestedStatus = true
                }

            }
            else {
                if (index !== -1) {
                    state.suggestedEvent[index].interestedStatus = false
                }

                if (state.profiles[data.eventId]) {
                    state.profiles[data.eventId].interestedStatus = false
                }

                const filteredEvents = state.interestedEvent.filter(event => event.eventId !== data.eventId)
                state.interestedEvent = filteredEvents
            }
        },
        updateGoingEvent(state, action) {
            const { method, data } = action.payload

            if (method === 'add') {

                if (state.profiles[data.eventId]) {
                    state.profiles[data.eventId].goingStatus = true
                }

                state.goingEvent.push(data)
            }
            else {

                if (state.profiles[data.eventId]) {
                    state.profiles[data.eventId].goingStatus = false
                }

                const filteredEvents = state.goingEvent.filter(event => event.eventId !== data.eventId)
                state.goingEvent = filteredEvents
            }
        },
        updateSearchQuerry(state, action) {
            state.searchQuerry = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventProfile.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchEventProfile.fulfilled, (state, action) => {
                state.loading = false
                state.profiles[action.payload.eventId] = action.payload
            })
            .addCase(fetchEventProfile.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

        builder
            .addCase(createEvent.pending, (state) => {
                state.creating = true
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.creating = false
                state.myEvent.push(action.payload)
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.creating = false
                state.error = action.error.message
            })

        builder
            .addCase(getSuggestedEvent.pending, (state) => {
                state.suggesedEventLoading = true
            })
            .addCase(getSuggestedEvent.fulfilled, (state, action) => {
                state.suggesedEventLoading = false
                state.suggestedEvent = action.payload
            })
            .addCase(getSuggestedEvent.rejected, (state, action) => {
                state.suggesedEventLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(getMyEvent.pending, (state) => {
                state.myEventLoading = true
            })
            .addCase(getMyEvent.fulfilled, (state, action) => {
                state.myEventLoading = false
                state.myEvent = action.payload
            })
            .addCase(getMyEvent.rejected, (state, action) => {
                state.myEventLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(getInterestedEvent.pending, (state) => {
                state.interestedEventLoading = true
            })
            .addCase(getInterestedEvent.fulfilled, (state, action) => {
                state.interestedEventLoading = false
                state.interestedEvent = action.payload
            })
            .addCase(getInterestedEvent.rejected, (state, action) => {
                state.interestedEventLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(getGoingEvent.pending, (state) => {
                state.goinEventLoading = true
            })
            .addCase(getGoingEvent.fulfilled, (state, action) => {
                state.goinEventLoading = false
                state.goingEvent = action.payload
            })
            .addCase(getGoingEvent.rejected, (state, action) => {
                state.goinEventLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(getInvitedEvent.pending, (state) => {
                state.invitationLoading = true
            })
            .addCase(getInvitedEvent.fulfilled, (state, action) => {
                state.invitationLoading = false
                state.invitaionEvents = action.payload
            })
            .addCase(getInvitedEvent.rejected, (state, action) => {
                state.invitationLoading = false
                state.error = action.error.message
            })

        builder
            .addCase(removeEventInvitation.fulfilled, (state, action) => {
                const { message, invitationId } = action.payload
                const filterInvite = state.invitaionEvents.filter(invitation => invitation.invitationId !== invitationId)
                if (message === 'Invitation removed successfully') {
                    state.invitaionEvents = filterInvite
                }
            })
        
        builder
            .addCase(searchEvent.pending, (state) => {
                state.searching = true
            })
            .addCase(searchEvent.fulfilled, (state, action) => {
                state.searching = false
                addContentToExistingEvent(action.payload, state.eventSearchHistory)
            })
            .addCase(searchEvent.rejected, (state, action) => {
                state.searchError = action.error.message
            })

            builder
            .addCase(getEventCategory.pending, (state) => {
                state.loadingCategory = true
            })
            .addCase(getEventCategory.fulfilled, (state, action) => {
                state.loadingCategory = false
                const { category, data} = action.payload
                state.eventCategory[category] = data
            })
            .addCase(getEventCategory.rejected, (state, action) => {
                state.loadingCategory = false
                state.categoryError = action.error.message
            })
    }
})

export const { updateEventInfo, updateGoingEvent, updateInterestedEvent, updateSearchQuerry } = eventSlice.actions
export default eventSlice.reducer;