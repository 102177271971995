// src/routesConfig.js
import React, { lazy } from 'react';
import GeneralSettings from './Pages/settings/General';
import NotFoundPage from './Pages/404-page'; // Import the NotFoundPage
import Terms from './Pages/terms an condition/terms';

const Login = lazy(() => import('./Components/Login'));
const Register = lazy(() => import('./Components/Register'));
const Forgot = lazy(() => import('./Components/Forgot'));
const Homepage = lazy(() => import('./Pages/Homepage'));
const Pro = lazy(() => import('./Pages/Pro'));
const Profile = lazy(() => import('./Pages/Profile'));
const Uploadpage = lazy(() => import('./Pages/Uploadpage'));
const FriendPage = lazy(() => import('./Pages/FriendPage'));
const Photopage = lazy(() => import('./Pages/Photopage'));
const Groupbtn = lazy(() => import('./Pages/Groupbtn'));
const Pagesbtn = lazy(() => import('./Pages/Pagesbtn'));
const Activities = lazy(() => import('./Pages/Activities'));
const Productbtn = lazy(() => import('./Pages/Productbtn'));
const MySideNav = lazy(() => import('./Pages/SideNav'));
const Page = lazy(() => import('./Pages/Pages'));
const Suggested = lazy(() => import('./Pages/SuggestedPage'));
const LikePage = lazy(() => import('./Pages/LikePage'));
const ViewGroup = lazy(() => import('./Pages/ViewGroup'));
const Viewpage = lazy(() => import('./Pages/ViewPage'));
const CoverBg = lazy(() => import('./Pages/CoverBg'));
const Newpage = lazy(() => import('./Pages/createNewpage/CreateNewpage'));
const ManagePage = lazy(() => import('./Pages/Manage/ManagePage'));
const Boast = lazy(() => import('./Pages/Boast/Boast'));
const SuggestedAccount = lazy(() => import('./Pages/Findfriend/SuggestedAccount'));
const EventsGoing = lazy(() => import('./Pages/Events/EventsGoing'));
const BrowseEvents = lazy(() => import('./Pages/Events/BrowseEvents'));
const RequestListItems = lazy(() => import('./Pages/Findfriend/RequestListItems'));
const InvitedEvents = lazy(() => import('./Pages/Events/InvitedEvents'));
const MyEvents = lazy(() => import('./Pages/Events/MyEvents'));
const EventsInterested = lazy(() => import('./Pages/Events/EventsInterested'));
const EventProfile = lazy(() => import('./Pages/Events/Profile/EventProfile'));
const PastEvents = lazy(() => import('./Pages/Events/PastEvents'));
const EventView = lazy(() => import('./Pages/Events/Profile/EventView'));
const Chat = lazy(() => import('./Pages/Chat/Chat'));
const VerifyOTP = lazy(() => import('./Pages/Verify/VerifyOTP'));
const PasswordReset = lazy(() => import('./Pages/Verify/Reset'));
const UserProfile = lazy(() => import('./Pages/userProfile/userProfile'));
const ViewStories = lazy(() => import('./Components/viewStories/viewStories'));
const PhotoStory = lazy(() => import('./Pages/story/photoStory'));
const TextStory = lazy(() => import('./Pages/story/textStory'));
const TextPost = lazy(() => import('./Pages/postModule/textPost'));
const ViewPost = lazy(() => import('./Components/view-post/viewPost'));
const RealsPage = lazy(() => import('./Components/realsModule/reals-page/reals-page'));
const ViewUserReals = lazy(() => import('./Components/realsModule/view-reals/view-user-reals'));
const GroupModule = lazy(() => import('./Pages/group-section'));
const EventsModule = lazy(() => import('./Pages/EventsModule/events-section'));
const ViewEvents = lazy(() => import('./Pages/events-page/events'));
const FriendListSidenav = lazy(() => import('./Pages/Findfriend/FListSidenav'));
const AlbumModule = lazy(() => import('./Pages/Albulm/Albumpage'));
const SavePostModule = lazy(() => import('./Pages/savePost/save-post'));
const LandingPage = lazy(() => import('./Pages/landing-page/landingpage'));
const Messages = lazy(() => import('./Pages/Messages'));
const Settings = lazy(() => import('./Pages/Settings'));


const routes = [
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  // { path: '/forgot', component: Forgot },
  { path: '/homepage', component: Homepage },
  // { path: '/pro', component: Pro },
  // { path: '/profile', component: Profile },
  // { path: '/uploadpage', component: Uploadpage },
  // { path: '/friendpage', component: FriendPage }, 
  // { path: '/photoPage', component: Photopage },
  // { path: '/groupbtn', component: Groupbtn },
  // { path: '/pagesbtn', component: Pagesbtn },
  // { path: '/activities', component: Activities },
  // { path: '/productbtn', component: Productbtn },
  // { path: '/sidenav', component: MySideNav },
  // { path: '/group', component: GroupModule },
  // { path: '/messages', component: Messages },
  // { path: '/settings', component: Settings },
  // { path: '/suggested', component: Suggested },
  // { path: '/likepage', component: LikePage },
  // { path: '/pages', component: Page },
  // { path: '/viewgroup/:groupId', component: ViewGroup },
  // { path: '/viewpage', component: Viewpage },
  // { path: '/coverbg', component: CoverBg },
  // { path: '/newpage', component: Newpage },
  // { path: '/managepage', component: ManagePage },
  // { path: '/Boastpage', component: Boast },
  // { path: '/findfriends', component: FriendListSidenav },
  // { path: '/album-module', component: AlbumModule },
  // { path: '/friendrequest', component: RequestListItems },
  // { path: '/suggestedaccount', component: SuggestedAccount },
  // { path: '/eventpage', component: EventsModule },
  // { path: '/Events Going', component: EventsGoing },
  // { path: '/Browse Events', component: BrowseEvents },
  // { path: '/Invited', component: InvitedEvents },
  // { path: '/My Events', component: MyEvents },
  // { path: '/view-events/:eventId', component: ViewEvents },
  // { path: '/Events Interested', component: EventsInterested },
  // { path: '/EventProfile', component: EventProfile },
  // { path: '/Past Events', component: PastEvents },
  // { path: '/savepost', component: SavePostModule },
  // { path: '/eventView', component: EventView },
  // { path: '/Chat', component: Chat },
  // { path: '/Verify/:email', component: VerifyOTP },
  // { path: '/Reset/:email/:otp', component: PasswordReset },
  // { path: '/userProfile/:userId', component: UserProfile },
  // { path: '/viewStories', component: ViewStories },
  // { path: '/create-text-story', component: TextStory },
  // { path: '/create-photo-story', component: PhotoStory },
  // { path: '/create-post', component: TextPost },
  // { path: '/view-post', component: ViewPost },
  // { path: '/reals-page', component: RealsPage },
  // { path: '/view-user-reals', component: ViewUserReals },
  // { path: '/setting', component: GeneralSettings },
  // { path: '/terms', component: Terms },
  { path: '/', component: LandingPage },
  { path: '*', component: NotFoundPage }, // Catch-all route for 404
];

export default routes;
