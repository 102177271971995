import { createSlice } from "@reduxjs/toolkit";
import { getPendingFriendRequests } from "./friendAction";

const initialState = {
  loading: false,
  message: "idle",
  error: null,

  requestLoading: false,
  friendRequests: [],
  requestError: null,
};

const friendSlice = createSlice({
  name: "friend",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPendingFriendRequests.pending, (state) => {
        state.requestLoading = true;
      })
      .addCase(getPendingFriendRequests.fulfilled, (state, action) => {
        state.requestLoading = false;
        state.friendRequests = action.payload;
      })
      .addCase(getPendingFriendRequests.rejected, (state, action) => {
        state.requestLoading = false;
        state.requestError = action.error.message;
      });
  },
});

export default friendSlice.reducer;
