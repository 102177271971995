import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../helper";
import { getAccessToken } from "../../helper/tokenStorage";

export const getPendingFriendRequests = createAsyncThunk(
  "friend-request/pending-request",
  async (userId) => {
    try {
      const token = await getAccessToken();
      const request = await fetch(
        `${apiUrl}/api/friends/pending-requests/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      if (response.status === "success") {
        const senderArray = response.data.map((item) => item.user);
        console.log("pending request", senderArray);
        return senderArray;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const sendFriendRequest = createAsyncThunk(
  "friend-request/send",
  async ({ userId, friendId }) => {
    try {
      const token = await getAccessToken();
      const request = await fetch(
        `${apiUrl}/api/friends/send-request/${userId}/${friendId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      console.log(response);
      if (response.status === "success") {
        console.log(response);
        return response.message;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const cancelFriendRequest = createAsyncThunk(
  "friend-request/cancel",
  async ({ userId, friendId }) => {
    try {
      const token = await getAccessToken();
      const request = await fetch(
        `${apiUrl}/api/friends/unsend-request/${userId}/${friendId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      if (response.status === "success") {
        console.log(response);
        return response.message;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const acceptFriendRequest = createAsyncThunk(
  "friend-request/accept",
  async ({ userId, friendId }) => {
    try {
      const token = await getAccessToken();
      const request = await fetch(
        `${apiUrl}/api/friends/accept-request/${userId}/${friendId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      if (response.status === "success") {
        console.log(response);
        return response.message;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const declineFriendRequest = createAsyncThunk(
  "friend-request/decline",
  async ({ userId, friendId }) => {
    try {
      const token = await getAccessToken();
      const request = await fetch(
        `${apiUrl}/api/friends/decline-request/${userId}/${friendId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      if (response.status === "success") {
        console.log(response);
        return response.message;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const unfriendUser = createAsyncThunk(
  "unfriends",
  async ({ userId, friendId }) => {
    try {
      const token = await getAccessToken();
      const request = await fetch(
        `${apiUrl}/api/friends/unfriend/${userId}/${friendId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      if (response.status === "success") {
        console.log(response);
        return response.message;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
