import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
      <div className="text-center">
        <div className="flex justify-center items-center mb-4">
          <img
            src="images/404.png"
            alt="404"
            className="w-72 h-auto max-w-full"
          />
        </div>
       <div className='mt-[10%]'>
       <p className="text-xl md:text-2xl mb-4">OPPS! PAGE NOT FOUND</p>
        <button
          onClick={() => window.location.href = '/homepage'}
          className="px-6 py-3 bg-yellow-500 text-black font-semibold rounded hover:bg-yellow-400 transition-colors focus:outline-none focus:ring-2 focus:ring-yellow-300"
        >
          BACK TO HOME
        </button>
       </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
