import { createSlice } from "@reduxjs/toolkit";
import { getPostComments } from "./commentAction";

const initialState = {
    loading: false,
    error: null
}

const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPostComments.pending, (state) => {
                state.loading = true
            })
            .addCase(getPostComments.fulfilled, (state) => {
                state.loading = false
            })
            .addCase(getPostComments.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    }

})

export default commentSlice.reducer