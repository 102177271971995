import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const tabs = [
    {
        name: 'General',
        content: `
        <h2 class="text-xl font-bold mb-2">General Information</h2>
        <p class="mx-4 mb-3">Welcome to the blackdefynition.com website (the "Site"). These terms & conditions ("Terms and Conditions") apply to the site, and all of its divisions, subsidiaries, and affiliate-operated Internet sites, which reference these Terms and Conditions. This website is owned and operated by Blackdefynition. For this website, "we," "us," and "our" all refer to blackdefynition.com. The site reserves the right to change, modify, add, or remove portions of the Terms and Conditions of Use and the Terms and Conditions of Sale at any time. Changes will be effective when posted on the site with no other notice provided. Please check these terms and conditions regularly for updates. Your continued use of the site following the posting of changes to these Terms and Conditions constitutes your acceptance of those changes.</p>
        <p class="mx-4">Kindly review the Terms and Conditions listed below diligently before using this website, as your use of the website indicates your agreement to be wholly bound by its Terms and Conditions without modification. You agree that if you are unsure of the meaning of any part of these Terms and Conditions or have any questions regarding the Terms and Conditions, you will not hesitate to contact us for clarification. These Terms and Conditions fully govern the use of this website.</p>
        `
    },
    {
        name: 'Policies',
        content: `
        <h2 class="text-xl font-bold mb-2">Our Policies</h2>
        <p class="mx-4">Our policies are designed to ensure the safety and security of our users. Please take a moment to review our policies below:</p>
        <ul class="list-disc pl-5 mx-4">
          <li><strong>Privacy Policy:</strong> We value your privacy and strive to protect your personal information.</li>
          <li><strong>Terms of Service:</strong> Our terms outline the acceptable use of our services.</li>
          <li><strong>Refund Policy:</strong> Details about our refund process and eligibility criteria.</li>
        </ul>
        `
    },
    {
        name: 'Products',
        content: `
        <h2 class="text-xl font-bold mb-2">Our Products</h2>
        <p class="mx-4">Explore our range of products designed to meet your needs:</p>
        <ul class="list-disc pl-5 mx-4">
          <li><strong>Product A:</strong> A powerful tool for managing projects.</li>
          <li><strong>Product B:</strong> An analytics platform to track performance.</li>
          <li><strong>Product C:</strong> A collaborative workspace for teams.</li>
        </ul>
        `
    },
    {
        name: 'Help Centre',
        content: `
        <h2 class="text-xl font-bold mb-2">Help Centre</h2>
        <p class="mx-4">If you need assistance, our Help Centre is here to support you:</p>
        <ul class="list-disc pl-5 mx-4">
          <li><strong>FAQs:</strong> Frequently asked questions about our services.</li>
          <li><strong>Contact Support:</strong> Reach out to our support team via email or live chat.</li>
          <li><strong>User Guides:</strong> Detailed guides and tutorials on how to use our platform.</li>
        </ul>
        `
    },
];

const TabContent = ({ content }) => (
    <TransitionGroup>
        <CSSTransition key={content} timeout={300} classNames="fade">
            <div className="p-4 bg-customGray text-white rounded shadow-md" dangerouslySetInnerHTML={{ __html: content }} />
        </CSSTransition>
    </TransitionGroup>
);

function Terms() {
    const [activeTab, setActiveTab] = useState(tabs[0]);

    return (
        <div className="min-h-screen bg-customDark">
            <div className="bg-black py-4 mb-6">
                <div className="flex flex-col md:flex-row justify-between items-center p-2">
                    <h1 className="text-2xl font-bold text-white mb-4 md:mb-0">Terms and Conditions</h1>
                    <nav className="flex flex-wrap justify-center md:justify-start space-x-0 md:space-x-4">
                        {tabs.map((tab) => (
                            <button
                                key={tab.name}
                                onClick={() => setActiveTab(tab)}
                                className={`py-2 px-4 text-white transition-colors duration-300 ease-in-out rounded ${activeTab.name === tab.name ? 'bg-customGold text-black' : 'bg-transparent border border-white hover:bg-customGold hover:text-black'}`}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>

            <div className="flex justify-center">
                <div className="w-full md:w-4/5 lg:w-3/5 xl:w-2/5">
                    <TabContent content={activeTab.content} />
                </div>
            </div>
        </div>
    );
}

export default Terms;
