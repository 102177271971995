import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiImage, FiUser } from 'react-icons/fi';
import { AiOutlineFile, AiOutlineUser, AiOutlineTeam, AiOutlineVideoCamera, AiOutlineArrowLeft } from 'react-icons/ai';
import { AiOutlineInfoCircle, AiOutlineCreditCard, AiOutlineQuestionCircle } from 'react-icons/ai';
import { FaChevronDown, FaChevronRight, FaCog, FaBell, FaLink, FaUser, FaMapMarkerAlt, FaImage, FaCheckCircle, FaInfoCircle, FaShieldAlt, FaLock, FaSignOutAlt, FaUserLock, FaUserTimes } from 'react-icons/fa';
import RightSideFriend from '../../Components/FindfriendPage/RightSideFriend';
import MyPost from '../../Components/ProfilePage/MyPost';
import GroupRender from '../../Components/ProfilePage/my-group-profile';

const languages = [
  { value: 'en', label: 'English' },
  { value: 'ha', label: 'Hausa' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'ig', label: 'Igbo' },
  { value: 'fu', label: 'Fula' },
  { value: 'kr', label: 'Kanuri' },
  { value: 'ib', label: 'Ibibio' },
  { value: 'tv', label: 'Tiv' },
  { value: 'ij', label: 'Ijaw' },
  { value: 'ur', label: 'Urhobo' },
];

const blockedUsersData = [
  { id: 1, name: 'Name of user', image: 'https://picsum.photos/100?random=1' },
  { id: 2, name: 'Name of user', image: 'https://picsum.photos/100?random=2' },
  { id: 3, name: 'Name of user', image: 'https://picsum.photos/100?random=3' },
  { id: 4, name: 'Name of user', image: 'https://picsum.photos/100?random=4' },
  { id: 5, name: 'Name of user', image: 'https://picsum.photos/100?random=5' },
  { id: 6, name: 'Name of user', image: 'https://picsum.photos/100?random=6' },
  { id: 7, name: 'Name of user', image: 'https://picsum.photos/100?random=7' },
  { id: 8, name: 'Name of user', image: 'https://picsum.photos/100?random=8' },
  { id: 9, name: 'Name of user', image: 'https://picsum.photos/100?random=9' },
  { id: 10, name: 'Name of user', image: 'https://picsum.photos/100?random=10' },
];


const Settings = () => {
  const [activeSection, setActiveSection] = useState('general');
  const [collapsedSections, setCollapsedSections] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const animatedComponents = makeAnimated();
  const [isEditing, setIsEditing] = useState(false);
  const [isVisible, setIsVisible] = useState({
    email: true,
    phone: true,
    birthdate: true,
    school: true,
    work: true,
  });

  const [currentPage, setCurrentPage] = useState('info');
  const [content, setContent] = useState(null);
  const [blockedUsers, setBlockedUsers] = useState(blockedUsersData);
  const [status, setStatus] = useState(true);
  const [privateAccount, setPrivateAccount] = useState(true);
  const [coverImage, setCoverImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const [code, setCode] = useState('');
  const inputRefs = useRef([]);

  const handleCodeChange = (e, idx) => {
    const newCode = [...code];
    newCode[idx] = e.target.value;
    setCode(newCode);

    // Focus the next input if the current one is filled
    if (e.target.value && idx < inputRefs.current.length - 1) {
      inputRefs.current[idx + 1].focus();
    }
  };

  const handleToggle = () => {
    setIsEnabled(!isEnabled);
  };

  const toggleStatus = () => {
    setPopupType('status');
    setShowPopup(true);
  };

  const togglePrivateAccount = () => {
    setPopupType('privateAccount');
    setShowPopup(true);
  };

  const handlePopupAction = (action) => {
    if (action === 'accept') {
      if (popupType === 'status') {
        setStatus(!status);
      } else if (popupType === 'privateAccount') {
        setPrivateAccount(!privateAccount);
      }
    }
    setShowPopup(false);
  };

  const handleCoverChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setCoverImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAvatarImage(URL.createObjectURL(e.target.files[0]));
    }
  };


  const unblockUser = (id) => {
    setBlockedUsers(blockedUsers.filter(user => user.id !== id));
  };

  const handleCardClick = (content) => {
    setContent(content);
    setCurrentPage('detail');
  };

  const handleBackClick = () => {
    setCurrentPage('info');
    setContent(null);
  };

  const toggleVisibility = (field) => {
    setIsVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const toggleSection = (section) => {
    setCollapsedSections(prevState =>
      prevState.includes(section)
        ? prevState.filter(item => item !== section)
        : [...prevState, section]
    );
  };

  const sections = [
    { name: 'General', id: 'general', icon: <FaCog /> },
    { name: 'Notification settings', id: 'notification', icon: <FaBell /> },
    { name: 'Social links', id: 'social', icon: <FaLink /> },
    {
      name: 'Profile', id: 'profile', icon: <FaUser />, subSections: [
        { name: 'Profile settings', id: 'profileSettings', icon: <FaUser /> },
        { name: 'My Addresses', id: 'addresses', icon: <FaMapMarkerAlt /> },
        { name: 'Avatar & Cover', id: 'avatar', icon: <FaImage /> },
        // { name: 'Verification', id: 'verification', icon: <FaCheckCircle /> },
        { name: 'My information', id: 'information', icon: <FaInfoCircle /> },
      ]
    },
    {
      name: 'Security', id: 'security', icon: <FaShieldAlt />, subSections: [
        { name: 'Privacy', id: 'privacy', icon: <FaShieldAlt /> },
        { name: 'Password', id: 'password', icon: <FaLock /> },
        // { name: 'Manage Session', id: 'session', icon: <FaSignOutAlt /> },
        { name: 'Two-factor authentication', id: '2fa', icon: <FaUserLock /> },
      ]
    },
    { name: 'Blocked Users', id: 'blocked', icon: <FaUserTimes /> },
    { name: 'Disable Account', id: 'disable', icon: <FaSignOutAlt /> },
  ];

  const notifications = [
    { label: 'Someone accept my friend request.', checked: true },
    { label: 'Someone send me friend request.', checked: true },
    { label: 'Someone pokes me.', checked: true },
    { label: 'Someone mentioned me.', checked: true },
  ];



  const renderContent = () => {
    switch (activeSection) {
      case 'general':
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">General Setting</h2>
            <div className="bg-customGray p-4 rounded-md animate-slideIn">
              <h3 className="text-xl font-semibold mb-2">About</h3>
              <p className="mb-2">What Blackdefynition is all about...</p>
              <p className="mb-2">Benefits</p>
              <p className="mb-2">Terms and Conditions</p>
            </div>

            <div className="bg-customGray p-4 mt-4 rounded-md animate-slideIn">
              <h3 className="text-xl font-semibold mb-2">Language</h3>
              <div className="flex flex-col space-y-4">
                <Select
                  components={animatedComponents}
                  options={languages}
                  placeholder="Select a language"
                  className="bg-customDark rounded-md text-white"
                  onChange={(option) => setSelectedLanguage(option)}
                  value={selectedLanguage}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: '#1C1B19',
                      borderColor: 'transparent',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: '#1C1B19',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'white',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? '#BE9524' : '#1C1B19',
                      color: state.isSelected ? 'black' : 'white',
                      '&:hover': {
                        backgroundColor: '#BE9524',
                        color: 'black',
                      },
                    }),
                  }}
                />
              </div>
            </div>
            <div className="bg-customGray p-4 mt-4 rounded-md animate-slideIn">
              <h3 className="text-xl font-semibold mb-2">Your orders and payments</h3>
              <div className="flex items-center mb-2">
                <AiOutlineInfoCircle className="mr-2" />
                <p className="">Contact info</p>
              </div>
              <div className="flex items-center mb-2">
                <AiOutlineCreditCard className="mr-2" />
                <p className="">Payment method</p>
              </div>
              <div className="flex items-center mb-2">
                <AiOutlineQuestionCircle className="mr-2" />
                <p className="">Help and support</p>
              </div>
            </div>
          </div>
        );
      case 'notification':
        return (
          <div className="bg-customGray p-4 rounded-md animate-slideIn">
            <div className="bg-customDark p-4 rounded-md w-1/2 mx-auto mt-4 ">
              <h2 className="text-2xl font-bold mb-4">Notification Setting</h2>
              <div className="text-left">
                <p className="font-bold mb-2">Notify me when</p>
                <div className="flex flex-col space-y-2">
                  {notifications.map((notification, index) => (
                    <label key={index} className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 accent-[#BE9524] text-cutombg-customGray"
                        defaultChecked={notification.checked}
                      />
                      <span className="ml-2 text-white">{notification.label}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      case 'social':
        return (
          <div className="bg-customGray p-4 rounded-md  animate-slideIn">
            {/* <h2 className="text-2xl font-bold mb-4">Social Links</h2> */}
            <div className="bg-customDark p-4 rounded-md w-2/3 mx-auto mt-4 animate-slideIn">
              <h2 className="text-2xl font-bold mb-4 animate-slideIn">Social Links</h2>
              <p className="mb-4 animate-slideIn">Please drop your profile link to all the social websites listed below</p>
              <div className="grid grid-cols-2 gap-4 mt-4 animate-slideIn">
                <input type="text" placeholder="LinkedIn Link" className="bg-customGray p-2 rounded-md text-white w-full" />
                <input type="text" placeholder="YouTube Link" className="bg-customGray p-2 rounded-md text-white w-full" />
              </div>
              <button className="mt-[20%] bg-customGray text-white p-2 rounded-md w-full animate-slideIn">Save</button>
            </div>
          </div>
        );
      case 'profileSettings':
        return (
          <div className="min-h-screen flex items-center p-2 justify-center bg-customGray animate-slideIn text-white ">
            <div className="relative w-full max-w-2xl p-4 bg-customDark rounded-lg shadow-lg  animate-slideIn">
              {!isEditing ? (
                <div className="space-y-4  ">
                  <div className="text-lg font-bold">Profile Setting</div>
                  <div className=''>
                    <div className="font-bold">Gabriel Jones Maribel</div>
                    <div>Nickname/username</div>
                    <div className="mt-2">
                      Lorem ipsum dolor sit amet consectetur. Vulputate blandit dolor scelerisque proin eu viverra. Semper
                      tincidunt neque vulputate eget.
                    </div>
                  </div>
                  <div className='border-b' />
                  <div className="space-y-2 relative">
                    <div>Contact info</div>
                    <div className="flex justify-between items-center">
                      <div>{isVisible.email ? '@Email' : '******'}</div>
                      <div className="flex items-center space-x-2">
                        <FontAwesomeIcon
                          icon={isVisible.email ? faEye : faEyeSlash}
                          onClick={() => toggleVisibility('email')}
                          className="cursor-pointer"
                        />
                        <span onClick={() => setIsEditing(true)} className="cursor-pointer ">Edit</span>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div>{isVisible.phone ? 'Phone number' : '******'}</div>
                      <div className="flex items-center space-x-2">
                        <FontAwesomeIcon
                          icon={isVisible.phone ? faEye : faEyeSlash}
                          onClick={() => toggleVisibility('phone')}
                          className="cursor-pointer"
                        />
                        <span onClick={() => setIsEditing(true)} className="cursor-pointer ">Edit</span>
                      </div>
                    </div>
                  </div>
                  <div className='border-b' />
                  <div className="space-y-2 relative">
                    <div>Birthday & Gender</div>
                    <div className="flex justify-between items-center">
                      <div>{isVisible.birthdate ? 'Date of birth' : '******'}</div>
                      <div className="flex items-center space-x-2">
                        <FontAwesomeIcon
                          icon={isVisible.birthdate ? faEye : faEyeSlash}
                          onClick={() => toggleVisibility('birthdate')}
                          className="cursor-pointer"
                        />
                        <span onClick={() => setIsEditing(true)} className="cursor-pointer ">Edit</span>
                      </div>
                    </div>
                    <div>Female</div>
                  </div>
                  <div className='border-b' />
                  <div className="space-y-2 relative">
                    <div>School & Relationship</div>
                    <div className="flex justify-between items-center">
                      <div>{isVisible.school ? 'Name of school graduated from' : '******'}</div>
                      <div className="flex items-center space-x-2">
                        <FontAwesomeIcon
                          icon={isVisible.school ? faEye : faEyeSlash}
                          onClick={() => toggleVisibility('school')}
                          className="cursor-pointer"
                        />
                        <span onClick={() => setIsEditing(true)} className="cursor-pointer ">Edit</span>
                      </div>
                    </div>
                    <div>Engaged</div>
                  </div>
                  <div className='border-b' />
                  <div className="space-y-2 relative">
                    <div>Work & link</div>
                    <div className="flex justify-between items-center">
                      <div>{isVisible.work ? 'Name of place of work' : '******'}</div>
                      <div className="flex items-center space-x-2">
                        <FontAwesomeIcon
                          icon={isVisible.work ? faEye : faEyeSlash}
                          onClick={() => toggleVisibility('work')}
                          className="cursor-pointer"
                        />
                        <span onClick={() => setIsEditing(true)} className="cursor-pointer ">Edit</span>
                      </div>
                    </div>
                    <div>The company’s link</div>
                  </div>
                </div>
              ) : (
                <div className="space-y-4  animate-slideIn">
                  <div className="text-lg font-bold  animate-slideIn">Profile Setting</div>
                  <div className="space-y-2">
                    <input type="text" placeholder="First name" className="w-full p-2 bg-customGray rounded " />
                    <input type="text" placeholder="Last name" className="w-full p-2 bg-customGray rounded" />
                    <input type="text" placeholder="Middle name (optional)" className="w-full p-2 bg-customGray rounded" />
                    <input type="text" placeholder="Profile name (Nickname)" className="w-full p-2 bg-customGray rounded" />
                  </div>
                  <div className="space-y-2">
                    <textarea placeholder="About me" className="w-full p-2 bg-customGray rounded"></textarea>
                    <input type="email" placeholder="Email" className="w-full p-2 bg-customGray rounded" />
                    <input type="tel" placeholder="Phone" className="w-full p-2 bg-customGray rounded" />
                  </div>
                  <div className="space-y-2">
                    <input type="date" placeholder="Birthday" className="w-full p-2 bg-customGray rounded" />
                    <select className="w-full p-2 bg-customGray rounded">
                      <option>Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Other</option>
                    </select>
                  </div>
                  <div className="space-y-2">
                    <input type="text" placeholder="School" className="w-full p-2 bg-customGray rounded" />
                    <input type="text" placeholder="Relationship" className="w-full p-2 bg-customGray rounded" />
                  </div>
                  <div className="space-y-2">
                    <input type="text" placeholder="Working at" className="w-full p-2 bg-customGray rounded" />
                    <input type="url" placeholder="Company’s website" className="w-full p-2 bg-customGray rounded" />
                  </div>
                  <button
                    onClick={() => setIsEditing(false)}
                    className=" flex mt-4 px-4 py-2 bg-customGray items-center justify-center rounded">
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      case 'addresses':
        return (
          <div className="bg-customGray p-4 rounded-md animate-slideIn">
            {/* <h2 className="text-2xl font-bold mb-4">My Addresses</h2> */}
            <div className="flex flex-col items-center bg-customDark p-6 rounded-lg w-full max-w-2xl mx-auto">
              <h1 className="text-white text-lg mb-4">My Addresses</h1>
              <div className="flex w-full">
                <div className="w-1/2 p-4">
                  <div className="w-full h-48 bg-customGray rounded-lg flex items-center justify-center">
                    <label className="cursor-pointer text-white flex flex-col items-center">
                      <svg className="w-12 h-12 text-gray-400 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                      </svg>
                      <span>Add location</span>
                      <input type="file" className="hidden" />
                    </label>
                  </div>
                </div>
                <div className="w-1/2 p-4 flex flex-col space-y-4">
                  <select className="w-full p-2 bg-customGray text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-white">
                    <option value="">State</option>
                    <option value="state1">State 1</option>
                    <option value="state2">State 2</option>
                  </select>
                  <select className="w-full p-2 bg-customGray text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-white">
                    <option value="">Country</option>
                    <option value="country1">Country 1</option>
                    <option value="country2">Country 2</option>
                  </select>
                  <button className="px-4 py-2 bg-customGray text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-white">Save</button>
                </div>
              </div>
            </div>
          </div>
        );
      case 'avatar':
        return (
          <div className="bg-customGray p-4 rounded-md">
            {/* <h2 className="text-2xl font-bold mb-4">Avatar & Cover</h2> */}
            <div className="flex flex-col items-center bg-customDark p-6 rounded-lg animate-slideIn">
              <div className="w-full relative">
                {coverImage ? (
                  <img src={coverImage} alt="Cover" className="w-full h-40 object-cover rounded-lg" />
                ) : (
                  <div className="w-full h-40 bg-customGray rounded-lg flex items-center justify-center">
                    <label className="cursor-pointer">
                      <FiImage className="w-10 h-8 " />
                      <input type="file" className="hidden" onChange={handleCoverChange} />
                    </label>
                  </div>
                )}
              </div>
              <div className="relative mt-4 ">
                {avatarImage ? (
                  <img src={avatarImage} alt="Avatar" className="w-24 h-24  rounded-full border-4 border-custombg-customDark object-cover" />
                ) : (
                  <div className="w-24 h-24 bg-customGray rounded-full flex items-center justify-center animate-slideIn">
                    <label className="cursor-pointer">
                      <FiUser className="w-10 h-10 " />
                      <input type="file" className="hidden" onChange={handleAvatarChange} />
                    </label>
                  </div>
                )}
              </div>
              <button className="mt-4 px-4 py-2 bg-customGray text-white rounded-lg">Save</button>
            </div>
          </div>
        );
      case 'verification':
        return (
          <div className="bg-customGray p-4 rounded-md">
            <h2 className="text-2xl font-bold mb-4">Verification</h2>
          </div>
        );
      case 'information':
        return (
          <div className="flex items-center justify-center min-h-screen bg-customGray text-white p-4">
            {currentPage === 'info' ? (
              <div className="w-full max-w-4xl animate-slideIn">
                <h2 className="text-2xl font-bold mb-4">Download My Information</h2>
                <p className="mb-6">Please choose which information you would like to download</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div
                    className="bg-customDark p-8 rounded-md flex flex-col items-center cursor-pointer transform transition duration-300 hover:scale-105"
                    onClick={() => handleCardClick('posts')}
                  >
                    <AiOutlineFile className="text-4xl mb-4" />
                    <p>My posts</p>
                  </div>
                  <div
                    className="bg-customDark p-8 rounded-md flex flex-col items-center cursor-pointer transform transition duration-300 hover:scale-105"
                    onClick={() => handleCardClick('friends')}
                  >
                    <AiOutlineUser className="text-4xl mb-4" />
                    <p>My pals</p>
                  </div>
                  <div
                    className="bg-customDark p-8 rounded-md flex flex-col items-center cursor-pointer transform transition duration-300 hover:scale-105"
                    onClick={() => handleCardClick('groups')}
                  >
                    <AiOutlineTeam className="text-4xl mb-4" />
                    <p>My groups</p>
                  </div>
                  <div
                    className="bg-customDark p-8 rounded-md flex flex-col items-center cursor-pointer transform transition duration-300 hover:scale-105"
                    onClick={() => handleCardClick('reels')}
                  >
                    <AiOutlineVideoCamera className="text-4xl mb-4" />
                    <p>My reels</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full max-w-4xl animate-slideIn">
                <button onClick={handleBackClick} className="text-white mb-4">
                  <AiOutlineArrowLeft className="text-2xl" />
                </button>
                {content === 'posts' && (
                  <div className="bg-customDark p-8 rounded-md">
                    <h3 className="text-xl font-semibold mb-4">My Posts</h3>
                    {/* Replace with actual content */}
                    <MyPost />
                  </div>
                )}
                {content === 'friends' && (
                  <div className="bg-customDark p-8 rounded-md">
                    <h3 className="text-xl font-semibold mb-4">Your Friends</h3>
                    {/* Replace with actual content */}
                    <RightSideFriend />
                  </div>
                )}
                {content === 'groups' && (
                  <div className="bg-customDark p-8 rounded-md">
                    <h3 className="text-xl font-semibold mb-4">My Groups</h3>
                    {/* Replace with actual content */}
                    <GroupRender />
                  </div>
                )}
                {content === 'reels' && (
                  <div className="bg-customDark p-8 rounded-md">
                    <h3 className="text-xl font-semibold mb-4">My Reels</h3>
                    {/* Replace with actual content */}
                    <p>Here are your reels...</p>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case 'privacy':
        return (
          <div className="bg-customGray p-4 rounded-md animate-slideIn">
            <div className="bg-customDark text-white p-6 max-w-md mx-auto mt-10 rounded-lg animate-slideIn">
              <h2 className="text-2xl font-bold mb-6">Privacy Settings</h2>
              <div className="space-y-6">
                <div className="flex items-center justify-between">
                  <span>Status</span>
                  <div className="flex items-center space-x-2">
                    <span>{status ? 'Online' : 'Offline'}</span>
                    <button
                      onClick={toggleStatus}
                      className={`relative inline-flex items-center h-6 rounded-full w-11 ${status ? 'bg-customGray' : 'bg-customGray'}`}
                    >
                      <span
                        className={`transform transition ease-in-out duration-200 inline-block w-4 h-4 rounded-full bg-[#BE9524] ${status ? 'translate-x-6' : 'translate-x-1'}`}
                      ></span>
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <span>Private Account</span>
                  <div className="flex items-center space-x-2">
                    <span>{privateAccount ? 'Turn on' : 'Turn off'}</span>
                    <button
                      onClick={togglePrivateAccount}
                      className={`relative inline-flex items-center h-6 rounded-full w-11 ${privateAccount ? 'bg-customGray' : 'bg-customGray'}`}
                    >
                      <span
                        className={`transform transition ease-in-out duration-200 inline-block w-4 h-4 rounded-full bg-[#BE9524] ${privateAccount ? 'translate-x-6' : 'translate-x-1'}`}
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full py-2 mt-[10%] rounded bg-customGray cursor-not-allowed"
              >
                Save
              </button>
            </div>

            {showPopup && (
              <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                <div className="bg-customDark text-white p-6 max-w-md mx-auto rounded-lg">
                  <div className="flex flex-col items-center">
                    <div className="mb-4">
                      <img src="images/privacy.png" alt="Lock Icon" className="w-12 h-12" />
                    </div>
                    <h2 className="text-2xl font-bold mb-2">Private Account</h2>
                    <p className=" mb-6 text-center">
                      Enabling this option will restrict users from viewing your photos, posts, and stories on your profile.
                    </p>
                    <div className="flex space-x-4">
                      <button
                        onClick={() => handlePopupAction('accept')}
                        className="bg-[#BE9524] text-black py-2 px-4 rounded"
                      >
                        Turn On
                      </button>
                      <button
                        onClick={() => handlePopupAction('decline')}
                        className="bg-customGray text-white py-2 px-4 rounded"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );



      case 'password':
        return (
          <div className="bg-customGray p-4 rounded-md animate-slideIn">
            {/* <h2 className="text-2xl font-bold mb-4">Password</h2> */}
            <div className="bg-customDark text-white p-6 max-w-2xl mx-auto mt-10 rounded-lg animate-slideIn">
              <h2 className="text-2xl font-bold mb-6">Change Password</h2>
              <p className=" mb-6">
                Choose a strong password and use at least 8 characters. Avoid passwords that are too obvious or from another site, or something. Please note that you can only change your password once in 3 months before you can make another change of password.
              </p>
              <form>
                <div className="mb-4">
                  <label className="block mb-2" htmlFor="currentPassword">Current password</label>
                  <input type="password" id="currentPassword" className="w-full p-2 rounded bg-customGray text-gray-200" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2" htmlFor="newPassword">New password</label>
                  <input type="password" id="newPassword" className="w-full p-2 rounded bg-customGray text-gray-200" />
                </div>
                <div className="mb-6">
                  <label className="block mb-2" htmlFor="confirmPassword">Confirm password</label>
                  <input type="password" id="confirmPassword" className="w-full p-2 rounded bg-customGray text-gray-200" />
                </div>
                <button type="submit" className="w-full py-2 rounded bg-customGray  cursor-not-allowed">Save</button>
              </form>
            </div>
          </div>
        );
      // case 'session':
      //   return (
      //     <div className="bg-customGray p-4 rounded-md">
      //       <h2 className="text-2xl font-bold mb-4">Manage Session</h2>
      //     </div>
      //   );

      case '2fa':
        return (
          <div className="bg-customGray p-4 rounded-md animate-slideIn">
            {!isEnabled && (
              <div className="flex flex-col items-center text-center rounded-md mt-4 animate-slideIn">
                <FaUserLock className="text-6xl mb-4" />
                <p className="mb-6">
                  Turn on 2-step login to level up your account’s security. Once turned on, you'll use both your password and
                  a 6-digit security code sent to your phone or email to log in.
                </p>
              </div>
            )}

            <div className="bg-customDark p-4 flex flex-col items-center rounded-md w-1/2 mx-auto mt-4 animate-slideIn">
              {!isEnabled && (
                <div className="flex items-center justify-between w-full mb-4">
                  <div className="flex flex-col">
                    <h2 className="text-[14px] font-bold mb-1">Two-Factor Authentication</h2>
                    <span className="text-[16px]">{isEnabled ? 'Enabled' : 'Disabled'}</span>
                  </div>
                  <button
                    onClick={handleToggle}
                    className={`relative inline-flex items-center h-6 rounded-full w-11 ${isEnabled ? 'bg-green-500' : 'bg-customGray'}`}
                  >
                    <span
                      className={`transform transition ease-in-out duration-200 inline-block w-4 h-4 rounded-full bg-[#BE9524] ${isEnabled ? 'translate-x-6' : 'translate-x-1'}`}
                    ></span>
                  </button>
                </div>
              )}

              {isEnabled && (
                <div className="w-full mt-4">
                  <p className="mb-4">
                    A code has been sent to your email to complete the 2-step login process. Please use the email registered with Blackdefynition for this process to enable 2FA. Enter the 6-digit pin to complete the process.
                  </p>
                  <div className="flex justify-center mb-4">
                    {[...Array(6)].map((_, idx) => (
                      <input
                        key={idx}
                        type="text"
                        value={code[idx]}
                        onChange={(e) => handleCodeChange(e, idx)}
                        maxLength="1"
                        className="w-10 h-10 m-1 text-center rounded-md border bg-black border-gray-300"
                        ref={(el) => (inputRefs.current[idx] = el)}
                      />
                    ))}
                  </div>
                  <button className="bg-[#BE9524] text-black py-2 px-4 rounded">Continue</button>
                </div>
              )}
            </div>
          </div>
        );
      case 'blocked':
        return (
          <div className="bg-customGray p-4 rounded-md animate-fadeIn animate-slideIn">
            <div className="bg-customDark text-white p-6 max-w-4xl mx-auto mt-10 rounded-lg animate-fadeIn animate-slideIn">
              <h2 className="text-2xl font-bold mb-6">Blocked Users</h2>
              <p className=" mb-6">
                You and the user won't get any notifications, calls or messages from each other. So unblock users to get all the notifications, calls, and you will be able to message one another.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {blockedUsers.map(user => (
                  <div key={user.id} className="flex items-center justify-between bg-customGray p-4 rounded-lg">
                    <div className="flex items-center">
                      <img src={user.image} alt={user.name} className="w-10 h-10 rounded-full mr-4" />
                      <span>{user.name}</span>
                    </div>
                    <button
                      onClick={() => unblockUser(user.id)}
                      className="bg-customDark  py-2 px-4 rounded hover:bg-gray-600"
                    >
                      Unblock
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      case 'disable':
        return (
          <div className="flex items-center justify-center min-h-screen bg-customGray text-white p-4">
            <div className="bg-customDark p-8 rounded-md w-full max-w-md animate-slideIn">
              <h2 className="text-2xl font-bold mb-4">Disable Account</h2>
              <div className="flex flex-col items-center text-center">
                <AiOutlineDelete className="text-6xl mb-4" />
                <p className="mb-6">Are you sure you want to delete your account, and leave our network?</p>
                <div className="flex space-x-4">
                  <button className="bg-customGray text-white py-2 px-4 rounded hover:bg-customGold transition duration-300">
                    Disable Account
                  </button>
                  <button className="bg-customGray py-2 px-4 rounded hover:bg-customGold transition duration-300">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen bg-[#1C1B19] text-white">
      <div className="w-[25%] p-4 bg-[#1C1B19] text-white h-full flex flex-col overflow-y-auto">
        <h2 className="text-xl font-bold p-4">BLACKDEFYNITION</h2>
        <nav>
          {sections.map((section) => (
            <div key={section.id}>
              {section.subSections ? (
                <>
                  <div
                    onClick={() => toggleSection(section.id)}
                    className={`p-2 cursor-pointer rounded flex items-center justify-between transition-colors duration-300 ${collapsedSections.includes(section.id) ? 'bg-customGray' : 'hover:bg-customGray'}`}
                  >
                    <span className="flex items-center">
                      {section.icon}
                      <span className="ml-2">{section.name}</span>
                    </span>
                    {collapsedSections.includes(section.id) ? <FaChevronRight /> : <FaChevronDown />}
                  </div>
                  {!collapsedSections.includes(section.id) && (
                    <div className="ml-4">
                      {section.subSections.map((subSection) => (
                        <div
                          key={subSection.id}
                          onClick={() => setActiveSection(subSection.id)}
                          className={`p-4 cursor-pointer rounded flex items-center transition-colors duration-300 ${activeSection === subSection.id ? 'bg-[#BE9524] text-black' : 'hover:bg-customGray'}`}
                        >
                          {subSection.icon}
                          <span className="ml-2">{subSection.name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <div
                  onClick={() => setActiveSection(section.id)}
                  className={`p-4 cursor-pointer rounded flex items-center transition-colors duration-300 ${activeSection === section.id ? 'bg-[#BE9524] text-black' : 'hover:bg-customGray'}`}
                >
                  {section.icon}
                  <span className="ml-2">{section.name}</span>
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
      <div className="flex-1 p-6 overflow-y-auto">
        {renderContent()}
      </div>
    </div>
  );
};

export default Settings;
