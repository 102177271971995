export const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export function simplifyFriendsList(originalList) {
  return originalList.map((friendObject) => ({
    userId: friendObject.friend.userId,
    email: friendObject.friend.email,
    firstName: friendObject.friend.firstName,
    lastName: friendObject.friend.lastName,
    UserName: friendObject.friend.UserName,
    profilePhoto: friendObject.friend.profilePhoto,
    friendshipStatus: friendObject.friendshipStatus.message,
  }));
}
